import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Grid,
    Typography,
    Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqItemsLeft = [
        {
            question: "What is Transit Media Advertising?",
            answer: "Transit media advertising involves placing ads on vehicles, transit stations, and spaces in high-demand cities. This includes buses, metro trains, taxis, auto-rickshaws, and airports in locations like Delhi, Mumbai, Bangalore, Hyderabad, and Chennai. It’s a high-impact OOH (Out-of-Home) strategy for reaching commuters effectively in metro cities and high-traffic zones.",
        },
        {
            question: "Why Choose Transit Media Advertising for My Brand?",
            answer: "Transit media advertising ensures your brand achieves high visibility by targeting urban areas such as Noida, Gurgaon, Pune, and Ahmedabad. It provides a cost-effective way to achieve location-specific exposure in cities with heavy daily commuter traffic. Perfect for brand promotions and product launches in densely populated metropolitan areas.",
        },

        {
            question: "What Types of Transit Media Do You Offer?",
            answer: (

                <div>
                    <h4>We offer comprehensive transit media options across India:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Bus Advertising :</strong> Reach audiences in Delhi NCR, Bengaluru, and Kolkata with dynamic bus interiors and exteriors.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Metro Advertising :</strong> Target high footfall at metro stations in Mumbai, Hyderabad, and Chennai.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Taxi Advertising :</strong> Ensure high visibility in busy zones like South Delhi, Andheri in Mumbai, and Whitefield in Bangalore.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Auto-Rickshaw Branding :</strong> Affordable branding for mass reach in cities like Lucknow, Jaipur, and Indore.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Airport Advertising :</strong> Engage elite travelers with high-tech displays in airports like IGI Delhi and Chhatrapati Shivaji Mumbai.
                        </div>
                    </div>
                </div>


            ),
        },

        { question: "How Effective is Transit Advertising Compared to Other OOH Media?", answer: "Transit advertising provides dynamic visibility and repetitive impressions in high-demand zones like Connaught Place (Delhi), Bandra (Mumbai), and Koramangala (Bangalore). Unlike static billboards, transit ads travel across key locations, ensuring greater engagement with diverse audiences over time." },
    ];

    const faqItemsRight = [
        {
            question: "What Are the Benefits of Metro Train Advertising?",

            answer: (

                <div>
                    <h4>Metro train advertising delivers unique benefits for urban areas:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>

                            <p>Delhi Metro Advertising targets millions of daily commuters with station branding and full-train wraps.</p>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <p>Mumbai Metro Campaigns ensure high engagement with a diverse audience in India’s busiest metro city.
                            </p>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>


                            <p>Branding opportunities like digital panels and train wraps effectively capture attention in cities like Chennai and Hyderabad.</p>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <p>Metro advertising provides extensive reach in urban hubs, ensuring maximum visibility in crowded metro stations.</p>
                        </div>

                    </div>
                </div>


            ),
        },

        {
            question: "How Do You Measure the ROI of Transit Media Advertising?",

            answer: (

                <div>
                    <h4>We measure ROI using advanced metrics tailored to locations like Delhi NCR, Pune, and Bangalore:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>

                            <p>Impressions: Based on commuter traffic data from city-specific transit authorities like DMRC and BMRCL.</p>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <p>Engagement surveys: Analyze audience reactions in high-traffic areas such as MG Road (Bangalore) and Marine Drive (Mumbai).
                            </p>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>


                            <p>Brand recall studies: Help gauge the effectiveness of campaigns in cities with heavy footfall like Hyderabad.</p>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <p>Digital tracking using QR codes or custom URLs allows precise measurement of interaction in top markets.</p>
                        </div>

                    </div>
                </div>


            ),

        },


        { question: "Is Transit Advertising Suitable for Small Businesses?", answer: "Yes, transit advertising is scalable and perfect for small businesses targeting high-traffic areas like Delhi’s South Extension, Mumbai’s Andheri West, and Kolkata’s Park Street. It’s affordable, customizable, and ideal for reaching local audiences or promoting region-specific products." },


        {
            question: "Which Cities Do You Cover for Transit Media Advertising?",

            answer: (
                <div>
                    <h4>We operate in over 20+ cities across India, offering premium ad spaces in high-demand locations:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Delhi NCR :</strong> Covering major hubs like Connaught Place, Gurgaon Cyber Hub, and Noida Sector 18.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Mumbai :</strong> Prime locations include Bandra, Andheri, and Churchgate.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Bangalore :</strong> Focus on MG Road, Whitefield, and Indiranagar for maximum reach.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Hyderabad & Chennai :</strong> Target high footfall areas like Hi-Tech City (Hyderabad) and T Nagar (Chennai).
                        </div>

                    </div>
                </div>


            ),
        },
    ];

    return (
        <Grid container sx={{ backgroundColor: "#E8E8E8" }}>
            <Container maxWidth="lg" sx={{ marginTop: "40px", marginBottom: "40px" }}>
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: "20px",
                        color: "red",
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    FAQ's
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        marginBottom: "30px",
                        fontSize: "14px",
                        color: "gray",
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    Elevate your brand recall and broaden your reach with a strategically <br />
                    crafted range of outdoor advertising services.
                </Typography>

                <Grid container spacing={4}>
                    {/* Left Column */}
                    <Grid item xs={12} sm={12} md={6}>
                        {faqItemsLeft.map((item, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleChange(`panel${index}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}bh-content`}
                                    id={`panel${index}bh-header`}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={12} sm={12} md={6}>
                        {faqItemsRight.map((item, index) => (
                            <Accordion
                                key={index + faqItemsLeft.length}
                                expanded={expanded === `panel${index + faqItemsLeft.length}`}
                                onChange={handleChange(`panel${index + faqItemsLeft.length}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + faqItemsLeft.length}bh-content`}
                                    id={`panel${index + faqItemsLeft.length}bh-header`}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default FAQ;
