// import React, { useState, useEffect } from "react";
// // MATERIAL-UI
// import { Typography, Grid, Box } from "@mui/material";

// // REACT-ROUTER-DOM
// import { useParams } from "react-router-dom";
// import { getProductsByState } from "../../redux/actions/Home";

// // Accordian
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// // TOASTI-FY
// import { ToastContainer, toast } from "react-toastify";

// // REACT-HELMET
// import { Helmet } from "react-helmet";

// import Cards from "../Card/Cards";

// import Popupcomponent from "../PopUp/Popupcomponent";

// // IMAGES
// import samOutdoor from "../../assets/images/samOutdoor.jpeg";

// import { useNavigate } from "react-router-dom";

// import videotwo from "../../assets/video/VideoWithoutText.mp4";
// import Faq from "../FAQ/Faq";

// const HoardingIn = () => {
//   // accordian
//   const [expanded, setExpanded] = React.useState(false);

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   const { param } = useParams();
//   const params = param.split("in-")[1];

//   const [DATA, setDATA] = useState(null);
//   const [ProductId, setProductId] = useState("");
//   console.log("hello......................... data", DATA);

//   useEffect(() => {
//     console.log("STATE VALUE", params);
//     getProductsByState(params.replaceAll("-", " ")).then((res) => {
//       console.log(res);
//       setDATA(res);
//       setProductId(res._id);
//     });
//   }, []);

//   const navigate = useNavigate();

//   // POP-UP
//   const [isOpen, setIsOpen] = useState(false);

//   const togglePopup = () => {
//     setIsOpen(!isOpen);
//   };

//   // Toastify
//   const showToastMessage = () => {
//     toast.success("Success Notification !", {
//       position: toast.POSITION.TOP_RIGHT,
//     });
//   };

//   return DATA === null || DATA?.length === 0 ? (
//     <>
//       <Helmet>
//         <title>
//           Top Outdoor Advertising Agency in {params.replaceAll("-", " ")}
//         </title>

//         {/* <meta
//           name="description"
//           content={`Outdoor advertising agency in ${params.replaceAll(
//             "-",
//             " "
//           )}`}
//         /> */}
//         <meta
//           name="description"
//           content={`Elevate Your Brand with the Top Outdoor Advertising Agency in ${params.replaceAll(
//             "-",
//             " "
//           )}.Discover Effective Outdoor Marketing Solutions. Contact Us Today. `}
//         />
//       </Helmet>

//       {isOpen && (
//         <Popupcomponent
//           ProductId={DATA === null || DATA?.length === 0 ? "n/a" : ProductId}
//           handleClose={togglePopup}
//         />
//       )}

//       {/* Video */}
//       <Grid container>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={12}
//           lg={12}
//           // display={{ xs: "none", lg: "block" }}
//         >
//           <Box
//             sx={{
//               width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
//               height: { xs: "30vh", sm: "30vh", md: "60vh", lg: "60vh" },
//             }}
//           >
//             <video
//               src={videotwo}
//               autoPlay
//               loop
//               muted
//               style={{ width: "100%", height: "100%", objectFit: "cover" }}
//             />
//             <Box
//               sx={{
//                 position: "absolute",
//                 width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
//                 height: { xs: "30vh", sm: "30vh", md: "60vh", lg: "60vh" },
//                 // backgroundColor: "red",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 marginTop: {
//                   xs: "-31vh",
//                   sm: "-31vh",
//                   md: "-61vh",
//                   lg: "-61vh",
//                 },
//               }}
//             >
//               {/* Text for Desktop*/}
//               <Box
//                 sx={{
//                   display: {
//                     xs: "none",
//                     sm: "none",
//                     md: "block",
//                     lg: "block",
//                   },
//                 }}
//               >
// <Typography
//   sx={{
//     display: "flex",
//     fontSize: "40px",
//     fontWeight: "600",
//     fontFamily: "Inter, sans-serif",
//     color: "#fff",
//     textAlign: "center",
//   }}
// >
//   Outdoor Advertising Agency in{" "}
//   {params
//     .replace(/-/g, " ")
//     .replace(/\b\w/g, (char) => char.toUpperCase())}
// </Typography>
//               </Box>

//               {/* Text for Mobile*/}
//               <Box sx={{ display: { md: "none", lg: "none" } }}>
// <Typography
//   sx={{
//     color: "#fff",
//     textAlign: "center",
//     fontSize: "20px",
//     fontWeight: "600px",
//     fontFamily: "Inter, sans-serif",
//     lineHeight: "30px",
//   }}
// >
//   Outdoor Advertising Agency in{" "}
//   {params
//     .replace(/-/g, " ")
//     .replace(/\b\w/g, (char) => char.toUpperCase())}
// </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>

//       {/* Content */}
//       <Grid container>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={0.5}
//           lg={0.5}
//           // sx={{ backgroundColor: "red" }}
//         ></Grid>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={5.5}
//           lg={5.5}
//           // sx={{ backgroundColor: "yellow" }}
//         >
//           {/* Image */}
//           <Box
//             sx={{
//               marginTop: {
//                 xs: "20px",
//                 sm: "20px",
//                 md: "50px",
//                 lg: "50px",
//               },
//               marginBottom: {
//                 xs: "20px",
//                 sm: "20px",
//                 md: "50px",
//                 lg: "50px",
//               },
//             }}
//           >
//             <Box
//               sx={{
//                 padding: {
//                   xs: "0px 10px 0px 10px",
//                   sm: "0px 10px 0px 10px",
//                   md: "0px 0px 0px 0px",
//                   lg: "0px 0px 0px 0px",
//                 },
//               }}
//             >
//               <Box
//                 component="img"
//                 src={samOutdoor}
//                 sx={{
//                   width: {
//                     xs: "100%",
//                     sm: "100%",
//                     md: "100%",
//                     lg: "100%",
//                   },
//                   height: {
//                     xs: "100%",
//                     sm: "100%",
//                     md: "100%",
//                     lg: "100%",
//                   },
//                   borderRadius: {
//                     xs: "5px",
//                     sm: "5px",
//                     md: "15px",
//                     lg: "15px",
//                   },

//                   border: "1px solid #FF1F2F",
//                 }}
//               />
//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={5.5}
//           lg={5.5}
//           // sx={{ backgroundColor: "pink" }}
//         >
//           <Box
//             sx={{
//               height: "100%",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               {/* Text for Desktop */}
//               <Box
//                 sx={{
//                   padding: {
//                     xs: "0px 20px 0px 20px",
//                     sm: "0px 20px 0px 20px",
//                     md: "0px 20px 0px 20px",
//                     lg: "0px 20px 0px 20px",
//                   },
//                   display: { xs: "none", sm: "none", md: "block", lg: "block" },
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     fontSize: {
//                       sx: "",
//                       sm: "",
//                       md: "20px",
//                       lg: "20px",
//                     },
//                     fontWeight: {
//                       sx: "",
//                       sm: "",
//                       md: "500",
//                       lg: "500",
//                     },
//                     fontFamily: "Inter, sans-serif",
//                   }}
//                 >
//                   Elevate Your Brand with the Top Outdoor Advertising Agency in
//                   <span
//                     style={{
//                       fontSize: "21px",
//                       fontWeight: "600",
//                       fontFamily: "Inter, sans-serif",
//                       color: "#FF1F2F",
//                     }}
//                   >
//                     {" "}
//                     {params
//                       .replace(/-/g, " ")
//                       .replace(/\b\w/g, (char) => char.toUpperCase())}
//                   </span>
//                   . Discover Effective Outdoor Marketing Solutions. Contact Us
//                   Today.
//                 </Typography>
//               </Box>
//               {/* Text for Mobile */}
//               <Box
//                 sx={{
//                   padding: {
//                     xs: "0px 20px 0px 20px",
//                     sm: "0px 20px 0px 20px",
//                     md: "0px 20px 0px 20px",
//                     lg: "0px 20px 0px 20px",
//                   },
//                   display: { md: "none", lg: "none" },
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     fontSize: "18px",
//                     fontWeight: "500",
//                     fontFamily: "Inter, sans-serif",
//                   }}
//                 >
//                   Elevate Your Brand with the Top Outdoor Advertising Agency in
//                   <span
//                     style={{
//                       fontSize: "19px",
//                       fontWeight: "600",
//                       fontFamily: "Inter, sans-serif",
//                       color: "#FF1F2F",
//                     }}
//                   >
//                     {" "}
//                     {params
//                       .replace(/-/g, " ")
//                       .replace(/\b\w/g, (char) => char.toUpperCase())}
//                   </span>
//                   . Discover Effective Outdoor Marketing Solutions. Contact Us
//                   Today.
//                 </Typography>
//               </Box>
//               {/* Button */}
//               <Box
//                 sx={{
//                   padding: {
//                     xs: "0px 20px 20px 20px",
//                     sm: "0px 20px 20px 20px",
//                     md: "0px 20px 0px 20px",
//                     lg: "0px 20px 0px 20px",
//                   },
//                 }}
//               >
//                 <Box
//                   sx={{
//                     marginTop: {
//                       xs: "20px",
//                       sm: "20px",
//                       md: "40px",
//                       lg: "40px",
//                     },
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       backgroundColor: "#FF1F2F",
//                       width: {
//                         xs: "180px",
//                         sm: "180px",
//                         md: "210px",
//                         lg: "210px",
//                       },
//                       height: {
//                         xs: "40px",
//                         sm: "40px",
//                         md: "45px",
//                         lg: "45px",
//                       },
//                       borderRadius: {
//                         xs: "40px",
//                         sm: "40px",
//                         md: "40px",
//                         lg: "40px",
//                       },
//                       alignItems: "center",
//                       display: "flex",
//                       justifyContent: "center",
//                       cursor: "pointer",
//                     }}
//                     onClick={togglePopup}
//                   >
//                     <Typography
//                       sx={{
//                         textAlign: "center",
//                         color: "white",
//                         fontFamily: "Inter, sans-serif",
//                         fontSize: {
//                           xs: "16px",
//                           sm: "16px",
//                           md: "18px",
//                           lg: "18px",
//                         },
//                         fontWeight: {
//                           xs: "500",
//                           sm: "500",
//                           md: "600",
//                           lg: "600",
//                         },
//                       }}
//                     >
//                       Request a Callback
//                     </Typography>
//                   </Box>
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={0.5}
//           lg={0.5}
//           // sx={{ backgroundColor: "red" }}
//         ></Grid>
//       </Grid>

//       {/* Accordian */}
//      <Faq/>
//     </>
//   ) : (
//     <>
//       {/* Video */}
//       <Grid container>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={12}
//           lg={12}
//           // display={{ xs: "none", lg: "block" }}
//         >
//           <Box
//             sx={{
//               width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
//               height: { xs: "30vh", sm: "30vh", md: "60vh", lg: "60vh" },
//             }}
//           >
//             <video
//               src={videotwo}
//               autoPlay
//               loop
//               muted
//               style={{ width: "100%", height: "100%", objectFit: "cover" }}
//             />
//             <Box
//               sx={{
//                 position: "absolute",
//                 width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
//                 height: { xs: "30vh", sm: "30vh", md: "60vh", lg: "60vh" },
//                 // backgroundColor: "red",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 marginTop: {
//                   xs: "-31vh",
//                   sm: "-31vh",
//                   md: "-61vh",
//                   lg: "-61vh",
//                 },
//               }}
//             >
//               {/* Text for Desktop*/}
//               <Box
//                 sx={{
//                   display: {
//                     xs: "none",
//                     sm: "none",
//                     md: "block",
//                     lg: "block",
//                   },
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     display: "flex",
//                     fontSize: "40px",
//                     fontWeight: "600",
//                     fontFamily: "Inter, sans-serif",
//                     color: "#fff",
//                     textAlign: "center",
//                   }}
//                 >
//                   Outdoor Advertising Agency in{" "}
//                   {params
//                     .replace(/-/g, " ")
//                     .replace(/\b\w/g, (char) => char.toUpperCase())}
//                 </Typography>
//               </Box>

//               {/* Text for Mobile*/}
//               <Box sx={{ display: { md: "none", lg: "none" } }}>
//                 <Typography
//                   sx={{
//                     color: "#fff",
//                     textAlign: "center",
//                     fontSize: "20px",
//                     fontWeight: "600px",
//                     fontFamily: "Inter, sans-serif",
//                     lineHeight: "30px",
//                   }}
//                 >
//                   Outdoor Advertising Agency in{" "}
//                   {params
//                     .replace(/-/g, " ")
//                     .replace(/\b\w/g, (char) => char.toUpperCase())}
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>

//       {/* Content */}
//       <Grid container>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={0.5}
//           lg={0.5}
//           // sx={{ backgroundColor: "red" }}
//         ></Grid>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={11}
//           lg={11}
//           // sx={{ backgroundColor: "yellow" }}
//         >
//           <Grid
//             container
//             sx={{
//               marginTop: {
//                 xs: "20px",
//                 sm: "20px",
//                 md: "50px",
//                 lg: "50px",
//               },
//               marginBottom: {
//                 xs: "20px",
//                 sm: "20px",
//                 md: "50px",
//                 lg: "50px",
//               },
//             }}
//           >
//             {DATA &&
//               DATA.map((item) => (
//                 <Cards
//                   key={item.id} // Add a unique key for each mapped element
//                   data={item}
//                   onClick={() => {
//                     navigate(
//                       `/${
//                         item?.urlcat?.toLowerCase()
//                           ? item?.urlcat?.toLowerCase()
//                           : item?.urlcat
//                       }/${item?.url ? item?.url : item?.address}/`,
//                       { state: { id: item._id } }
//                     );
//                     console.log("hello world", DATA);
//                   }}
//                 />
//               ))}
//           </Grid>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={0.5}
//           lg={0.5}
//           // sx={{ backgroundColor: "green" }}
//         ></Grid>
//       </Grid>

//       {/* Accordian */}
//       {/* For Desktop */}
// <Faq/>
//     </>
//   );
// };

// export default HoardingIn;

import React, { useState, useEffect } from "react";
// MATERIAL-UI
import { Typography, Grid, Box } from "@mui/material";

import { makeStyles } from "@mui/styles";
// Images
import {
  outdoorBanner,
  ourServicesbanner,
  weSeeThingsBgImage,
  blackRightArrow,
} from "../../DesignData/Images/Images";

// REACT-ROUTER-DOM
import { useParams } from "react-router-dom";
import { getProductsByState } from "../../redux/actions/Home";

// TOASTI-FY
import { ToastContainer, toast } from "react-toastify";

import {
  TopBannerMainTitle,
  HoardingInTopBannerMainTitle,
} from "../Styles/Styles";

// REACT-HELMET
import { Helmet } from "react-helmet";

import Cards from "../Card/Cards";

import Popupcomponent from "../PopUp/Popupcomponent";

// IMAGES
import samOutdoor from "../../assets/images/samOutdoor.jpeg";

import { useNavigate } from "react-router-dom";

import videotwo from "../../assets/video/VideoWithoutText.mp4";
import Faq from "../FAQ/Faq";

const useStyles = makeStyles(() => ({
  bgImageDesktop: {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${outdoorBanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "400px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bgImageMobile: {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${outdoorBanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "250px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  OurServicsBanner: {
    backgroundImage: `url(${ourServicesbanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  aboutOutdoorAdver: {
    backgroundImage: `url(${weSeeThingsBgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  sideGridBgColor: {
    // backgroundColor: "yellow",
  },
  MainGridBgColor: {
    // backgroundColor: "pink",
  },
}));

const HoardingIn = () => {
  // accordian

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [visibleItems, setVisibleItems] = useState(9);
  const handleViewMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 9); // load 9 more items when clicked
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { param } = useParams();
  const params = param.split("in-")[1];

  const [DATA, setDATA] = useState(null);
  const [ProductId, setProductId] = useState("");
  console.log("hello......................... data", DATA);

  useEffect(() => {
    console.log("STATE VALUE", params);
    getProductsByState(params.replaceAll("-", " ")).then((res) => {
      console.log(res);
      setDATA(res);
      setProductId(res._id);
    });
  }, []);

  // Fetch products
  useEffect(() => {
    setLoading(true);
    // Fetch Product from the API
    fetch(
      "https://www.januskoncepts.in/janusoohadmin/api/api_product/fetchproduct.php"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("hello unipole", data);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();

  // POP-UP
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  // Toastify
  const showToastMessage = () => {
    toast.success("Success Notification !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const classes = useStyles();

  return DATA === null || DATA?.length === 0 ? (
    <>
      <Helmet>
        <title>
          Top Outdoor Advertising Agency in {params.replaceAll("-", " ")}
        </title>
        <meta
          name="description"
          content={`Elevate Your Brand with the Top Outdoor Advertising Agency in ${params.replaceAll(
            "-",
            " "
          )}.Discover Effective Outdoor Marketing Solutions. Contact Us Today. `}
        />
      </Helmet>

      {isOpen && (
        <Popupcomponent
          ProductId={DATA === null || DATA?.length === 0 ? "n/a" : ProductId}
          handleClose={togglePopup}
        />
      )}

      <Box>
        {/* BANNER */}
        <Box>
          {/* desktop */}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Grid container className={classes.bgImageDesktop}>
              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                lg={11}
                className={classes.MainGridBgColor}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography sx={TopBannerMainTitle}>
                      Outdoor Advertising Agency in{" "}
                      <span style={{ color: "#FF1F2F" }}>
                        &nbsp;
                        {params
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>
            </Grid>
          </Box>

          {/* mobile */}
          <Box
            sx={{
              display: {
                md: "none",
                lg: "none",
              },
            }}
          >
            <Grid container className={classes.bgImageMobile}>
              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                lg={11}
                className={classes.MainGridBgColor}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography sx={HoardingInTopBannerMainTitle}>
                      Outdoor Advertising Agency in{" "}
                      <span style={{ color: "#FF1F2F" }}>
                        &nbsp;
                        {params
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* Content */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          // sx={{ backgroundColor: "red" }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5.5}
          lg={5.5}
          // sx={{ backgroundColor: "yellow" }}
        >
          {/* Image */}
          <Box
            sx={{
              marginTop: {
                xs: "20px",
                sm: "20px",
                md: "50px",
                lg: "50px",
              },
              marginBottom: {
                xs: "20px",
                sm: "20px",
                md: "50px",
                lg: "50px",
              },
            }}
          >
            <Box
              sx={{
                padding: {
                  xs: "0px 10px 0px 10px",
                  sm: "0px 10px 0px 10px",
                  md: "0px 0px 0px 0px",
                  lg: "0px 0px 0px 0px",
                },
              }}
            >
              <Box
                component="img"
                src={samOutdoor}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                  },
                  height: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                  },
                  borderRadius: {
                    xs: "5px",
                    sm: "5px",
                    md: "15px",
                    lg: "15px",
                  },

                  border: "1px solid #FF1F2F",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5.5}
          lg={5.5}
          // sx={{ backgroundColor: "pink" }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Text for Desktop */}
              <Box
                sx={{
                  padding: {
                    xs: "0px 20px 0px 20px",
                    sm: "0px 20px 0px 20px",
                    md: "0px 20px 0px 20px",
                    lg: "0px 20px 0px 20px",
                  },
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      sx: "",
                      sm: "",
                      md: "20px",
                      lg: "20px",
                    },
                    fontWeight: {
                      sx: "",
                      sm: "",
                      md: "500",
                      lg: "500",
                    },
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Elevate Your Brand with the Top Outdoor Advertising Agency in
                  <span
                    style={{
                      fontSize: "21px",
                      fontWeight: "600",
                      fontFamily: "Inter, sans-serif",
                      color: "#FF1F2F",
                    }}
                  >
                    {" "}
                    {params
                      .replace(/-/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                  </span>
                  . Discover Effective Outdoor Marketing Solutions. Contact Us
                  Today.
                </Typography>
              </Box>
              {/* Text for Mobile */}
              <Box
                sx={{
                  padding: {
                    xs: "0px 20px 0px 20px",
                    sm: "0px 20px 0px 20px",
                    md: "0px 20px 0px 20px",
                    lg: "0px 20px 0px 20px",
                  },
                  display: { md: "none", lg: "none" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Elevate Your Brand with the Top Outdoor Advertising Agency in
                  <span
                    style={{
                      fontSize: "19px",
                      fontWeight: "600",
                      fontFamily: "Inter, sans-serif",
                      color: "#FF1F2F",
                    }}
                  >
                    {" "}
                    {params
                      .replace(/-/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                  </span>
                  . Discover Effective Outdoor Marketing Solutions. Contact Us
                  Today.
                </Typography>
              </Box>
              {/* Button */}
              <Box
                sx={{
                  padding: {
                    xs: "0px 20px 20px 20px",
                    sm: "0px 20px 20px 20px",
                    md: "0px 20px 0px 20px",
                    lg: "0px 20px 0px 20px",
                  },
                }}
              >
                <Box
                  sx={{
                    marginTop: {
                      xs: "20px",
                      sm: "20px",
                      md: "40px",
                      lg: "40px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FF1F2F",
                      width: {
                        xs: "180px",
                        sm: "180px",
                        md: "210px",
                        lg: "210px",
                      },
                      height: {
                        xs: "40px",
                        sm: "40px",
                        md: "45px",
                        lg: "45px",
                      },
                      borderRadius: {
                        xs: "40px",
                        sm: "40px",
                        md: "40px",
                        lg: "40px",
                      },
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={togglePopup}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "white",
                        fontFamily: "Inter, sans-serif",
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "18px",
                          lg: "18px",
                        },
                        fontWeight: {
                          xs: "500",
                          sm: "500",
                          md: "600",
                          lg: "600",
                        },
                      }}
                    >
                      Request a Callback
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          // sx={{ backgroundColor: "red" }}
        ></Grid>
      </Grid>

      {/* Accordian */}
      <Faq />
    </>
  ) : (
    <>
      {/* Banner*/}
      <Box>
        {/* BANNER */}
        <Box>
          {/* desktop */}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Grid container className={classes.bgImageDesktop}>
              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                lg={11}
                className={classes.MainGridBgColor}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography sx={TopBannerMainTitle}>
                      Outdoor Advertising Agency in{" "}
                      <span style={{ color: "#FF1F2F" }}>
                        &nbsp;
                        {params
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>
            </Grid>
          </Box>

          {/* mobile */}
          <Box
            sx={{
              display: {
                md: "none",
                lg: "none",
              },
            }}
          >
            <Grid container className={classes.bgImageMobile}>
              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                lg={11}
                className={classes.MainGridBgColor}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography sx={HoardingInTopBannerMainTitle}>
                      Outdoor Advertising Agency in{" "}
                      <span style={{ color: "#FF1F2F" }}>
                        &nbsp;
                        {params
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* Content */}
      <Grid container sx={{ backgroundColor: "#D9D9D9" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          className={classes.sideGridBgColor}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          lg={11}
          sx={{
            marginTop: {
              xs: "20px",
              sm: "20px",
              md: "60px",
              lg: "60px",
            },
            marginBottom: "60px",
          }}
        >
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 3,
              md: 6,
              lg: 6,
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data &&
              data.slice(0, visibleItems).map((item) => (
                <Cards
                  key={item.id}
                  data={item}
                  onClick={() => {
                    navigate(
                      `/${
                        item?.category_url?.toLowerCase()
                          ? item?.category_url?.toLowerCase()
                          : item?.category_url
                      }/${item?.url ? item?.url : item?.address}/`,
                      { state: { id: item._id } }
                    );
                    console.log("hello world", DATA);
                  }}
                />
              ))}
          </Grid>

          {/* View more */}
          {data?.length > visibleItems && (
            <Box
              sx={{
                textAlign: "center",
                marginTop: "40px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  cursor: "pointer",
                  textAlign: "center",
                  fontFamily: "Inter, sans-serif",
                }}
                onClick={handleViewMore}
              >
                View More
              </Typography>
              <Box
                component="img"
                src={blackRightArrow}
                sx={{
                  width: {
                    xs: "10px",
                    sm: "10px",
                    md: "20px",
                    lg: "20px",
                  },
                  height: {
                    xs: "10px",
                    sm: "10px",
                    md: "15px",
                    lg: "15px",
                  },
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          className={classes.sideGridBgColor}
        ></Grid>
      </Grid>

      {/* Accordian */}
      {/* For Desktop */}
      <Faq />
    </>
  );
};

export default HoardingIn;
