import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Grid,
    Typography,
    Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqItemsLeft = [
        {
            question: "What is Metro Advertising, and How Does It Work?",
            answer: "Metro advertising involves placing advertisements inside and outside metro trains, on station walls, and digital screens. It targets millions of daily commuters in Delhi, Mumbai, Bangalore, Hyderabad, and Chennai metro networks, ensuring high visibility and engagement in urban areas.",
        },
        {
            question: "Which Metro Networks Do You Cover for Advertising?",
            answer: (
                <div>
                    <h4>JANUSOOH provides metro advertising services across India’s busiest metro systems, including:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Delhi Metro Advertising :</strong> Covers high-traffic stations like Rajiv Chowk, CP, and Noida City Center.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Mumbai Metro Campaigns :</strong> Targets areas like Andheri, Ghatkopar, and Bandra.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Bangalore Metro Advertising :</strong> Focuses on locations like MG Road, Whitefield, and Electronic City.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Hyderabad Metro Branding :</strong>  Offers ads in key zones like Hi-Tech City and Jubilee Hills.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Chennai Metro :</strong>  Covers busy stops like Anna Nagar and Chennai Central.
                        </div>

                    </div>
                </div>


            ),
        },
        {
            question: "What Are the Benefits of Metro Advertising?",
            answer: (
                <div>
                    <h4>Metro advertising offers unique advantages:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Massive Reach :</strong> Connect with millions of commuters in cities like Delhi and Mumbai.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Captive Audience :</strong> Engage travelers during their commute, ensuring high recall for your campaigns.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Location-Specific Targeting :</strong> Focus on high-footfall areas like Pragati Maidan in Delhi or Dadar in Mumbai.
                        </div>



                    </div>
                </div>


            ),
        },
        {
            question: "What Types of Metro Advertising Options Do You Offer?",

            answer: (
                <div>
                    <h4>We offer a range of metro advertising formats tailored to your needs:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Train Wraps :</strong> Eye-catching full-train advertisements that travel through high-demand zones like Delhi NCR and Bangalore.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Station Branding :</strong> Billboards, wall panels, and banners in premium locations like Rajiv Chowk or Andheri.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Digital Screens :</strong> Dynamic ads at metro stations in cities like Hyderabad and Chennai.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Train Interiors :</strong> Advertisements inside metro coaches targeting commuters in Mumbai and Prayagraj.
                        </div>

                    </div>
                </div>


            ),

        },
    ];

    const faqItemsRight = [
        { question: "How Does Delhi Metro Advertising Help My Brand?", answer: "Delhi Metro advertising guarantees exposure to over 5 million daily commuters across 280+ stations. High-demand zones like Dwarka Sector 21, HUDA City Centre, and Noida Electronic City provide excellent opportunities to connect with diverse audiences, from professionals to students." },
        {
            question: "What Are the Costs of Metro Advertising in Different Cities?",
            answer: (
                <div>
                    <h4>The cost of metro advertising varies depending on city, location, and format:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Delhi Metro :</strong>  Premium rates for high-traffic zones like Rajiv Chowk and CP.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Mumbai Metro :</strong>  Affordable rates for train interiors and mid-range for full wraps.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Hyderabad & Chennai :</strong> Competitive pricing for growing networks in urban hubs like Hi-Tech City and Anna Nagar.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Prayagraj Metro (Upcoming) :</strong> Expected budget-friendly rates for advertising in this emerging market.
                        </div>

                    </div>
                </div>


            ),

        },
        {
            question: "Why Choose Metro Advertising in Cities Like Mumbai and Bangalore?",
            answer: (

                <div>
                    <h4>Metro advertising in cities like Mumbai and Bangalore ensures high engagement in areas with:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>

                            <p>Busy transit zones like Andheri and MG Road, maximizing daily brand impressions.</p>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <p>Tech hubs like Whitefield in Bangalore, targeting a professional, high-income demographic.
                            </p>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>


                            <p>Cultural hotspots in Mumbai, such as Dadar and Bandra, engaging diverse audiences effectively.</p>

                        </div>


                    </div>
                </div>


            ),
        },
        {
            question: "Can Metro Advertising Support Specific Campaign Goals?",
            answer: (
                <div>
                    <h4>Yes, metro advertising is perfect for:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Product Launches :</strong>  Use full train wraps or station branding in Delhi or Mumbai metro networks for impactful visibility.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Brand Awareness :</strong>  Station ads in high-traffic areas like Noida Sector 18 and Ghatkopar.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Regional Promotions :</strong> Focus on growing cities like Chennai or Hyderabad metros.
                        </div>



                    </div>
                </div>


            ),
        },
    ];

    return (
        <Grid container sx={{ backgroundColor: "#E8E8E8" }}>
            <Container maxWidth="lg" sx={{ marginTop: "40px", marginBottom: "40px" }}>
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: "20px",
                        color: "red",
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    FAQ's
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        marginBottom: "30px",
                        fontSize: "14px",
                        color: "gray",
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    Elevate your brand recall and broaden your reach with a strategically <br />
                    crafted range of outdoor advertising services.
                </Typography>

                <Grid container spacing={4}>
                    {/* Left Column */}
                    <Grid item xs={12} sm={12} md={6}>
                        {faqItemsLeft.map((item, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleChange(`panel${index}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}bh-content`}
                                    id={`panel${index}bh-header`}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={12} sm={12} md={6}>
                        {faqItemsRight.map((item, index) => (
                            <Accordion
                                key={index + faqItemsLeft.length}
                                expanded={expanded === `panel${index + faqItemsLeft.length}`}
                                onChange={handleChange(`panel${index + faqItemsLeft.length}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + faqItemsLeft.length}bh-content`}
                                    id={`panel${index + faqItemsLeft.length}bh-header`}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default FAQ;
