import types from "../types";

const initialstate = {
  ShowForm: false,
  cartItems: null,
};

export const UtilReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.SHOW_FORM:
      const data = action.payload;
      return { ...state, ShowForm: data };
    case types.GET_CART_ITEMS:
      const x = action.payload;
      return { ...state, cartItems: x };
    case types.ADD_CART_ITEM:
      return { ...state, cartItems: action.payload };
    case types.REMOVE_FROM_CART:
      return { ...state, cartItems: action.payload };
    case types.UPDATE_CART:
      return { ...state, cartItems: action.payload };
    default:
      return state;
  }
};
