export const IterestedIn = [
  {
    id: "1",
    name: "Airport Branding",
  },
  {
    id: "2",
    name: "Inflight Branding",
  },
  {
    id: "3",
    name: "Mall Branding",
  },
  {
    id: "4",
    name: "Metro Advertising",
  },
  {
    id: "5",
    name: "Outdoor Advertising",
  },
  {
    id: "6",
    name: "Transit Media",
  },
];
