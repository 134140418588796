import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Grid,
    Typography,
    Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqItemsLeft = [
        {
            question: "What is Inflight Branding, and How Does It Work?",
            answer: "Inflight branding involves promoting your brand through advertising channels available on flights, such as seatback screens, tray tables, overhead compartments, and inflight magazines. It targets a premium audience traveling on airlines from locations like Delhi, Mumbai, Bangalore, Hyderabad, and Pune.",
        },
        {
            question: "Why Choose Inflight Branding for My Campaign?",
            answer: "Inflight branding offers access to a high-net-worth audience with extended exposure during long flights. It is highly effective for brands targeting business and leisure travelers flying from major hubs like Delhi IGI Airport, Mumbai Airport, and Bangalore Airport.",
        },
        {
            question: "Which Airlines Are Available for Inflight Advertising Through JANUSOOH?",
            answer: (
                <div>
                    <h4>We collaborate with leading domestic and international airlines:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>IndiGo Airlines :</strong>  Covers high-traffic domestic routes such as Delhi to Mumbai and Bangalore to Hyderabad.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Air India :</strong>  Focus on premium routes like Delhi to New York and Mumbai to London.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>SpiceJet and Go First :</strong> Perfect for affordable campaigns targeting Tier 2 cities like Lucknow and Prayagraj.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Vistara :</strong> A premium option for targeting elite audiences on routes like Delhi to Bangalore.
                        </div>

                    </div>
                </div>


            ),
        },
        {
            question: "What Types of Inflight Branding Options Do You Provide?",
            answer: (
                <div>
                    <h4>JANUSOOH offers various inflight advertising formats:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Seatback Screens :</strong>  Display video ads to engage travelers on flights from hubs like Delhi and Mumbai.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Tray Table Ads :</strong>  Innovative placements ideal for long-haul routes, including international flights from Hyderabad.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Inflight Magazines :</strong> Feature full-page ads in magazines like IndiGo’s Hello 6E for maximum visibility.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Overhead Bins and Wraps :</strong> High-impact placements visible throughout the journey on routes like Delhi to Pune.
                        </div>

                    </div>
                </div>


            ),
        },
    ];

    const faqItemsRight = [
        {
            question: "How Does Inflight Branding Benefit My Brand?",
            answer: (
                <div>
                    <h4>Inflight branding offers unique advantages:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Captive Audience :</strong>  Travelers spend hours in a confined space, ensuring maximum exposure for your ads.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Premium Reach :</strong>  Target affluent business travelers on routes like Mumbai to Bangalore or Delhi to London.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Brand Recall :</strong> Extended exposure during flights leads to better recall, especially on long-haul routes.
                        </div>



                    </div>
                </div>


            ),
        },
        {
            question: "How Cost-Effective Is Inflight Branding Compared to Other Channels?",
            answer: (
                <div>
                    <h4>Inflight branding is cost-effective because it combines premium targeting with extensive reach:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Domestic Routes :</strong>  Affordable campaigns on high-frequency flights like Delhi to Hyderabad or Mumbai to Pune.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>International Flights :</strong>  Premium pricing for high-value routes like Delhi to Dubai or Mumbai to Singapore.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Custom Packages :</strong> JANUSOOH creates flexible pricing options based on the airline, route, and ad format.
                        </div>



                    </div>
                </div>


            ),
        },
        {
            question: "Which Locations Offer Maximum ROI for Inflight Branding?",
            answer: (
                <div>
                    <h4>Routes originating from major metro cities deliver the best ROI due to high passenger traffic:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Delhi IGI Airport :</strong> The busiest airport with both domestic and international connectivity.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Mumbai Chhatrapati Shivaji Airport :</strong>  High-value business and leisure travelers.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Bangalore Kempegowda Airport :</strong> Tech-savvy and business-focused demographic.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Hyderabad Rajiv Gandhi Airport :</strong> Premium international traffic to Middle East and U.S. destinations.
                        </div>



                    </div>
                </div>


            ),
        },
        {
            question: "Can Inflight Branding Work for Regional or Smaller Cities?",
            answer: (
                <div>
                    <h4>Yes, inflight branding can effectively target regional markets:
                    </h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Prayagraj Airport :</strong> Campaigns on regional flights connecting Prayagraj to Delhi and Mumbai.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Lucknow :</strong>  Ideal for targeting Tier 2 cities with high-growth potential and emerging affluent audiences.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Jaipur and Chandigarh :</strong>  Reach regional travelers who frequently fly for business or leisure.
                        </div>

                    </div>
                </div>


            ),
        },
    ];

    return (
        <Grid container sx={{ backgroundColor: "#E8E8E8" }}>
            <Container maxWidth="lg" sx={{ marginTop: "40px", marginBottom: "40px" }}>
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: "20px",
                        color: "red",
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    FAQ's
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        marginBottom: "30px",
                        fontSize: "14px",
                        color: "gray",
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    Elevate your brand recall and broaden your reach with a strategically <br />
                    crafted range of outdoor advertising services.
                </Typography>

                <Grid container spacing={4}>
                    {/* Left Column */}
                    <Grid item xs={12} sm={12} md={6}>
                        {faqItemsLeft.map((item, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleChange(`panel${index}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}bh-content`}
                                    id={`panel${index}bh-header`}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={12} sm={12} md={6}>
                        {faqItemsRight.map((item, index) => (
                            <Accordion
                                key={index + faqItemsLeft.length}
                                expanded={expanded === `panel${index + faqItemsLeft.length}`}
                                onChange={handleChange(`panel${index + faqItemsLeft.length}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + faqItemsLeft.length}bh-content`}
                                    id={`panel${index + faqItemsLeft.length}bh-header`}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default FAQ;
