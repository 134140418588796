import { display, fontSize, fontWeight, textAlign } from "@mui/system";
import { weSeeThingsBgImage } from "../../DesignData/Images/Images";

// Section
export const SectionMainTitle = {
  fontSize: "26px",
  fontWeight: "500",
  textAlign: "center",
  lineHeight: "32px",
  color: "#FF1F2F",
  fontFamily: "Inter, sans-serif",
  display: {
    md: "none",
    lg: "none",
  },
};

export const SectionMainTitleForDesktop = {
  fontSize: "32px",
  fontWeight: "400",
  lineHeight: "35px",
  color: "#FF1F2F",
  textAlign: "left",
  fontFamily: "Inter, sans-serif",
  display: {
    xs: "none",
    sm: "none",
    md: "block",
    lg: "block",
  },
};

export const SectionDescriptionForMobile = {
  marginTop: "7px",
  fontSize: "16px",
  color: "#000",
  lineHeight: "20px",
  fontWeight: "500",
  textAlign: "center",
  padding: "0px 15px 0px 15px",
  display: {
    md: "none",
    lg: "none",
  },
  fontFamily: "Inter, sans-serif",
};

// Common Styles
export const CommonMainMargins = {
  marginTop: {
    xs: "30px",
    sm: "30px",
    md: "60px",
    lg: "60px",
  },
  marginBottom: {
    xs: "30px",
    sm: "30px",
    md: "60px",
    lg: "60px",
  },
};

// OUTDOOR component PAGES styling
export const OutdoorMainTitleDeskTop = {
  fontSize: "32px",
  color: "white",
  lineHeight: "30px",
  fontWeight: "400",
  textAlign: "center",
  fontFamily: "Inter, sans-serif",
  display: { xs: "none", sm: "none", md: "block", lg: "block" },
};

export const OutdoorMainTitleMobile = {
  fontSize: "26px",
  fontWeight: "500",
  textAlign: "center",
  lineHeight: "32px",
  color: "white",
  fontFamily: "Inter, sans-serif",
  display: {
    md: "none",
    lg: "none",
  },
};

export const OutdoorDescriptionDesktop = {
  marginTop: "10px",
  fontSize: "16px",
  lineHeight: "22px",
  color: "#fff",
  fontWeight: "400",
  fontFamily: "Inter, sans-serif",
  display: {
    xs: "none",
    sm: "none",
    md: "block",
    lg: "block",
  },
  textAlign: "center",
};

export const OutdoorDescriptionMobile = {
  marginTop: "7px",
  fontSize: "16px",
  color: "#fff",
  lineHeight: "20px",
  fontWeight: "500",
  textAlign: "center",
  padding: "0px 15px 0px 15px",
  display: {
    md: "none",
    lg: "none",
  },
  fontFamily: "Inter, sans-serif",
};

export const AboutOutdoorAdvertisingDesktop = {
  marginTop: "20px",
  fontSize: "16px",
  lineHeight: "22px",
  color: "#000",
  fontWeight: "400",
  textAlign: "center",
  fontFamily: "Inter, sans-serif",
  padding: "0px 150px 0px 150px",
  display: {
    xs: "none",
    sm: "none",
    md: "block",
    lg: "block",
  },
};

export const AboutOutdoorAdvertisingMobile = {
  fontSize: "15px",
  lineHeight: "22px",
  color: "#000000",
  fontWeight: "500",
  textAlign: "justify",
  padding: "0px 15px 0px 15px",
  fontFamily: "Inter, sans-serif",
  display: {
    md: "none",
    lg: "none",
  },
};

export const OutdoorOurProcessMainTitleDeskTop = {
  fontSize: "32px",
  color: "#FF1F2F",
  lineHeight: "30px",
  fontWeight: "400",
  textAlign: "center",
  fontFamily: "Inter, sans-serif",
  display: { xs: "none", sm: "none", md: "block", lg: "block" },
};

export const OutdoorOurProcessMainTitleMobile = {
  color: "#FF1F2F",
  fontSize: "26px",
  fontWeight: "500",
  textAlign: "center",
  lineHeight: "32px",
  fontFamily: "Inter, sans-serif",
  display: {
    md: "none",
    lg: "none",
  },
};

export const OutdoorOurProcessDescriptionDesktop = {
  marginTop: "10px",
  fontSize: "16px",
  lineHeight: "22px",
  color: "black",
  fontWeight: "400",
  fontFamily: "Inter, sans-serif",
  textAlign: "center",
  display: {
    xs: "none",
    sm: "none",
    md: "block",
    lg: "block",
  },
};

export const OutdoorOurProcessDescriptionMobile = {
  marginTop: "7px",
  fontSize: "16px",
  color: "#000",
  lineHeight: "20px",
  fontWeight: "500",
  textAlign: "center",
  padding: "0px 15px 0px 15px",
  display: {
    md: "none",
    lg: "none",
  },
  fontFamily: "Inter, sans-serif",
};

// WHAT OUTDOOR ADVERTSING
export const WhatAboutMainTitleDeskTop = {
  fontSize: "32px",
  color: "white",
  lineHeight: "30px",
  fontWeight: "400",
  textAlign: "center",
  fontFamily: "Inter, sans-serif",
  display: { xs: "none", sm: "none", md: "block", lg: "block" },
};

export const WhatAboutMainTitleMobile = {
  color: "white",
  fontSize: "26px",
  fontWeight: "500",
  textAlign: "center",
  lineHeight: "32px",
  fontFamily: "Inter, sans-serif",
  display: {
    md: "none",
    lg: "none",
  },
};

export const WhatAboutAdvertisingDescriptionDesktop = {
  margin: "20px 0px 0px 0px",
  fontSize: "16px",
  lineHeight: "22px",
  color: "white",
  fontWeight: 400,
  textAlign: "left",
  fontFamily: "Inter, sans-serif",
  display: {
    xs: "none",
    sm: "none",
    md: "block",
    lg: "block",
  },
};
export const WhatAboutAdvertisingDescriptionMobile = {
  marginTop: "10px",
  fontSize: "15px",
  lineHeight: "22px",
  color: "#fff",
  fontWeight: "500",
  textAlign: "justify",
  padding: "0px 15px 0px 15px",
  fontFamily: "Inter, sans-serif",
  display: {
    md: "none",
    lg: "none",
  },
};

export const WhatAboutAdvertisingAboutDescriptionMobile = {
  marginTop: "10px",
  fontSize: "15px",
  lineHeight: "22px",
  color: "#000",
  fontWeight: "500",
  textAlign: "justify",
  padding: "0px 15px 0px 15px",
  fontFamily: "Inter, sans-serif",
  display: {
    md: "none",
    lg: "none",
  },
};

// TYPES-----------------------------------------------------------------
export const titleAndDescpAlignemnt = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export const SubcategoryBannerMainTitle = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "50px",
    lg: "50px",
  },
  color: "#fff",
  lineHeight: "30px",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
};

export const SubcategoryBannerDescription = {
  marginTop: {
    xs: "10px",
    sm: "10px",
    md: "20px",
    lg: "20px",
  },
  fontSize: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
  lineHeight: {
    xs: "30px",
    sm: "30px",
    md: "22px",
    lg: "22px",
  },
  color: "#fff",
  fontWeight: {
    xs: "20px",
    sm: "20px",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
};

// CARDS-----------------------------------------
export const MainBox = {
  boxShadow: {
    xs: "3",
    sm: "3",
    md: "5",
    lg: "5",
  },
  transition: {
    md: "transform 0.1s, background-color 0.3s",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      transform: "scale(1.01)",
    },
    lg: "transform 0.1s, background-color 0.3s",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      transform: "scale(1.01)",
    },
  },
  backgroundColor: "#fff",
  cursor: "pointer",
  height: "100%",
  width: "100%",
};
export const CardsMarginForMobileView = {
  margin: {
    xs: "0px 20px 0px 20px",
    sm: "0px 10px 0px 10px",
    md: "0px 0px 0px 0px",
    lg: "0px 0px 0px 0px",
  },
};
export const cardImage = {
  height: {
    xs: "100%",
    sm: "100%",
    md: "100%",
    lg: "100%",
  },
  width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
  borderBottom: "1px solid red",
};
export const Maintitle = {
  marginTop: {
    xs: "9px",
    sm: "9px",
    md: "9px",
    lg: "9px",
  },

  fontFamily: "Inter, sans-serif",
  fontSize: {
    xs: "18px",
    sm: "18px",
    md: "17px",
    lg: "17px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "600",
    lg: "600",
  },
  lineHeight: {
    xs: "21px",
    sm: "21px",
    md: "20px",
    lg: "20px",
  },
  color: "#000000",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  // padding: "10px 0px 10px 0px",
  // textTransform: "capitalize",
};
export const TextBox = {
  // padding: "10px",
  // marginTop: "10px",
  // padding: "0px 10px 0px 10px",
  // backgroundColor:"orange",
  height: {
    xs: "100%",
    sm: "100%",
    md: "100%",
    lg: "100%",
  },
};
export const TitleBox = {
  // backgroundColor: "red",
  padding: "5px 10px 5px 10px",
};
export const Alignment = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  // marginTop:"10px"
  // backgroundColor: "yellow",
};
export const ColonsStyle = {
  width: {
    xs: "20px",
    sm: "20px",
    md: "16px",
    lg: "16px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "600",
    lg: "600",
  },
  fontFamily: "Inter, sans-serif",
};
export const StateAndCity = {
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "16px",
    lg: "16px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "600",
    lg: "600",
  },
  fontFamily: "Inter, sans-serif",
};
export const StateAndCityName = {
  fontSize: {
    xs: "15px",
    sm: "15px",
    md: "16px",
    lg: "16px",
  },
  fontWeight: {
    xs: "600",
    sm: "600",
    md: "600",
    lg: "600",
  },
  fontFamily: "Inter, sans-serif",
  textTransform: "capitalize",
  color: "#FF1F2F",
};
export const StateAndCityWidth = {
  // backgroundColor: "green",
  width: {
    xs: "70px",
    sm: "70px",
    md: "70px",
    lg: "70px",
  },
};
export const StateAndCityNameWidth = {
  // backgroundColor: "pink",
  width: "100%",
};
export const ColonsWidth = {
  // backgroundColor: "red",
  width: "20px",
};

// Header

export const HeaderBGcolor = {
  // backgroundColor: "rgb(8,8,8,0.4)",
  backgroundColor: "rgb(33, 33, 33, 0.6)",
};
export const HeaderLogoAndNavigators = {
  width: "100%",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: {
    xs: "flex-start",
    sm: "flex-start",
    md: "space-between",
    lg: "space-between",
  },
};

export const HeaderLogoStyle = {
  width: {
    xs: "45px",
    sm: "45px",
    md: "70px",
    lg: "70px",
  },
  height: {
    xs: "35px",
    sm: "35px",
    md: "50px",
    lg: "50px",
  },
  marginTop: {
    xs: "5px",
    sm: "5px",
    md: "10px",
    lg: "10px",
  },
  cursor: "pointer",
};

export const HeaderLogoAlignMent = {
  marginLeft: {
    xs: "6px",
    sm: "6px",
    md: "40px",
    lg: "40px",
  },
};

export const SideDrawerLogoAndCloseButtonAlign = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

export const SiderDrawerLogoStyle = {
  width: "70px",
  height: "50px",
  marginTop: "20px",
};

export const SiderDrawerCloseIconStyle = {
  width: "15px",
  height: "15px",
  position: "absolute",
  top: "10px",
  right: "20px",
  cursor: "pointer",
};

export const SideDrawerIcon = {
  width: "27px",
  height: "27px",
};

export const SideDrawerIconsAndTextAlign = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "20px",
  marginLeft: "20px",
};

export const SideDrawerTextStyle = {
  color: "#545353",
  fontSize: "20px",
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
};
export const HeaderCategories = {
  marginLeft: "20px",
  marginRight: "20px",
  color: "#000",
  fontSize: "15px",
  fontWeight: "600",
  fontFamily: "Inter, sans-serif",
  padding: "5px 0px 5px 0px",
};
export const HeaderCategoriesBox = {
  transition: "transform 0.3s ease, background-color 0.3s ease", // Smooth transition
  "&:hover": {
    backgroundColor: "red", // Change background color on hover
    transform: "scale(1.05)", // Slight zoom effect
    "& .MuiTypography-root": {
      color: "white", // Change typography color on hover
    },
  },
  cursor: "pointer",
};
// export const AuthModalstyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };
export const EmptyProfileImg = {
  width: "30px",
  height: "30px",
  cursor: {
    xs: "none",
    sm: "none",
    md: "pointer",
    lg: "pointer",
  },
};
// Headr Text red color effect
const buttonHoverStyle = {
  "&:hover": {
    cursor: "pointer",
    color: "red",
  },
};
export const HeaderNavigatorsStyle = {
  color: "#fff",
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
  cursor: "pointer",
  ...buttonHoverStyle,
};

// Faq
export const FaqMainMargins = {
  marginTop: {
    xs: "30px",
    sm: "30px",
    md: "60px",
    lg: "60px",
  },
  marginBottom: {
    xs: "30px",
    sm: "30px",
    md: "60px",
    lg: "60px",
  },
};
// OUTDOOR
export const MainMargins = {
  marginTop: {
    xs: "30px",
    sm: "30px",
    md: "60px",
    lg: "60px",
  },
  marginBottom: {
    xs: "30px",
    sm: "30px",
    md: "60px",
    lg: "60px",
  },
};

export const HoardingInTopBannerMainTitle = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "50px",
    lg: "50px",
  },
  color: "#fff",
  lineHeight: "30px",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
  marginTop: {
    xs: "10px",
    sm: "20px",
  },
  textAlign: "center",
};
export const TopBannerMainTitle = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "50px",
    lg: "50px",
  },
  color: "#fff",
  lineHeight: "30px",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
  marginTop: {
    xs: "10px",
    sm: "20px",
  },
};
export const TopbannerDescription = {
  marginTop: {
    xs: "10px",
    sm: "10px",
    md: "20px",
    lg: "20px",
  },
  fontSize: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
  lineHeight: {
    xs: "30px",
    sm: "30px",
    md: "22px",
    lg: "22px",
  },
  color: "#fff",
  fontWeight: {
    xs: "20px",
    sm: "20px",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
};
export const MainTitle = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "32px",
    lg: "32px",
  },
  color: "white",
  lineHeight: "30px",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
};
export const Description = {
  marginTop: {
    xs: "10px",
    sm: "10px",
    md: "20px",
    lg: "20px",
  },
  fontSize: {
    xs: "20px",
    sm: "20px",
    md: "16px",
    lg: "16px",
  },
  lineHeight: {
    xs: "30px",
    sm: "30px",
    md: "22px",
    lg: "22px",
  },
  color: "white",
  fontWeight: {
    xs: "20px",
    sm: "20px",
    md: "400",
    lg: "400",
  },
  textAlign: "center",
  fontFamily: "Inter, sans-serif",
  display: {
    xs: "none",
    sm: "none",
    md: "block",
    lg: "block",
  },
};
export const MarginBtwnTypo = {
  marginTop: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
};
export const AboutOutdoorMainTitle = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "32px",
    lg: "32px",
  },
  color: "#000",
  lineHeight: { xs: "35px", sm: "35px", md: "30px", lg: "30px" },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
  textAlign: "center",
};
export const AboutOutdoorDescription = {
  marginTop: {
    xs: "10px",
    sm: "10px",
    md: "20px",
    lg: "20px",
  },
  fontSize: {
    xs: "20px",
    sm: "20px",
    md: "16px",
    lg: "16px",
  },
  lineHeight: {
    xs: "30px",
    sm: "30px",
    md: "22px",
    lg: "22px",
  },
  color: "#000",
  fontWeight: {
    xs: "400",
    sm: "400",
    md: "400",
    lg: "400",
  },
  textAlign: {
    xs: "left",
    sm: "left",
    md: "center",
    lg: "center",
  },
  fontFamily: "Inter, sans-serif",
  padding: {
    xs: "0px 10px 0px 10px",
    sm: "0px 10px 0px 10px",
    md: "0px 150px 0px 150px",
    lg: "0px 150px 0px 150px",
  },
};
export const contentInGrid = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const OutdoorMainBox = {
  height: "300px",
  width: "250px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const greyBox = {
  width: "200px",
  height: "250px",
  backgroundColor: "#D9D9D9",
  position: "absolute",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 10px 0px 10px",
};
export const icon = {
  width: "45px",
  height: "45px",
};
export const greyboxTitle = {
  marginTop: "10px",
  fontWeight: "400",
  fontSize: "30px",
  color: "#000000",
  fontFamily: "Inter, sans-serif",
};
export const greyBoxDesc = {
  fontWeight: "400",
  fontSize: "13px",
  color: "#000000",
  textAlign: "center",
  fontFamily: "Inter, sans-serif",
};
export const OurProcessDescription = {
  marginTop: {
    xs: "10px",
    sm: "10px",
    md: "20px",
    lg: "20px",
  },
  fontSize: {
    xs: "20px",
    sm: "20px",
    md: "16px",
    lg: "16px",
  },
  lineHeight: {
    xs: "30px",
    sm: "30px",
    md: "22px",
    lg: "22px",
  },
  color: "#000",
  fontWeight: {
    xs: "20px",
    sm: "20px",
    md: "400",
    lg: "400",
  },
  textAlign: "left",
  fontFamily: "Inter, sans-serif",
  display: {
    xs: "none",
    sm: "none",
    md: "block",
    lg: "block",
  },
};
export const OurProcessMainTitle = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "32px",
    lg: "32px",
  },
  color: "#000",
  lineHeight: "30px",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
  textAlign: {
    xs: "center",
    sm: "center",
    md: "left",
    lg: "left",
  },
};

// UNIPOLE-------------------------------------------------
export const FaqMainTitle = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "32px",
    lg: "32px",
  },
  color: " #FF1F2F",
  lineHeight: "30px",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  textAlign: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "center",
  },
  fontFamily: "Inter, sans-serif",
};
export const FaqDescription = {
  marginTop: {
    xs: "15px",
    sm: "15px",
    md: "10px",
    lg: "10px",
  },
  fontSize: {
    xs: "18px",
    sm: "18px",
    md: "16px",
    lg: "16px",
  },
  lineHeight: {
    xs: "22px",
    sm: "22px",
    md: "22px",
    lg: "22px",
  },
  color: "#000000",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  textAlign: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "center",
  },
  padding: {
    xs: "0px 10px 0px 10px",
    sm: "0px 10px 0px 10px",
    md: "0px 0px 0px 0px",
    lg: "0px 0px 0px 0px",
  },
  fontFamily: "Inter, sans-serif",
  display: {
    xs: "none",
    sm: "none",
    md: "block",
    lg: "block",
  },
};
export const unipoleBannerMainTitle = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "50px",
    lg: "50px",
  },
  color: "#FF1F2F",
  lineHeight: "30px",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
};

export const UnipoleBannerDescription = {
  marginTop: {
    xs: "10px",
    sm: "10px",
    md: "20px",
    lg: "20px",
  },
  fontSize: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
  lineHeight: {
    xs: "30px",
    sm: "30px",
    md: "27px",
    lg: "27px",
  },
  color: "#fff",
  fontWeight: {
    xs: "20px",
    sm: "20px",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
  textAlign: "center",
};

export const SearchWhiteBox = {
  position: "absolute",
  top: "52%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px",
  height: "50px",
  backgroundColor: "#FFFFFF",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
};
export const SearchWhiteBoxForMobile = {
  backgroundColor: "#D9D9D9",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  width: "100%",
  marginTop: "40px",
};

export const SearchBarbutton = {
  width: "120px",
  height: "42px",
  backgroundColor: "red",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  fontFamily: "Inter, sans-serif",
};
export const SearchBarbuttonMobile = {
  width: "100px",
  height: "35px",
  backgroundColor: "#FF1F2F",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  fontFamily: "Inter, sans-serif",
};

export const searchBarButtonText = {
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "18px",
    lg: "18px",
  },
  color: "white",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
};

// Footer
export const FooterMainMargins = {
  marginTop: {
    xs: "20px",
    sm: "20px",
    md: "60px",
    lg: "60px",
  },
  marginBottom: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
  // border: "1px solid red",
  backgroundColor: "#FFFFFF",
};
export const FooterInternalGridHeight = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: {
    xs: "250px",
    sm: "250px",
    md: "250px",
    lg: "250px",
  },
  width: "100%",
  padding: {
    xs: "0px 10px 0px 10px",
    sm: "0px 10px 0px 10px",
    md: "0px 0px 0px 0px",
    lg: "0px 0px 0px 0px",
  },
};
export const FooterMainTitle = {
  fontSize: {
    xs: "22px",
    sm: "22px",
    md: "22px",
    lg: "22px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  color: "#FF1F2F",
  fontFamily: "Inter, sans-serif",
};
export const FooterSmallSubTitles = {
  marginTop: {
    xs: "0px",
    sm: "0px",
    md: "0px",
    lg: "0px",
  },
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "15px",
    lg: "15px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  lineHeight: {
    xs: "23px",
    sm: "23px",
    // md: "20px",
    // lg: "20px",
  },
  color: "#fff",
  fontFamily: "Inter, sans-serif",
};
export const FooterSmallSubtitlesMarginForMobile = {
  marginTop: {
    xs: "20px",
    sm: "20px",
    md: "0px",
    lg: "0px",
  },
};
export const FooterInputStyle = {
  padding: "10px",
  borderRadius: "200px",
  fontSize: "13px",
  fontFamily: "Inter, sans-serif",
  width: "250px",
  height: "62",
  boxSizing: "border-box",
  border: "none",
  borderColor: "#FF1F2F",
  backgroundColor: "#FF1F2F",
  color: "white",
};
export const ContactUsInputStyle = {
  padding: "10px",
  borderRadius: "10px",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  height: "50px",
  boxSizing: "border-box",
  border: "none",
  borderColor: "#FF1F2F",
  backgroundColor: "#fff",
  color: "#FF1F2F",
  fontWeight: "500",
};
export const ContactUsInputPadding = {
  padding: {
    xs: "0px 10px 0px 10px",
    sm: "0px 10px 0px 10px",
    md: "0px 10px 0px 10px",
    lg: "0px 10px 0px 10px",
  },
  marginTop: {
    xs: "20px",
    sm: "20px",
    md: "0px",
    lg: "0px",
  },
};

export const ContactusMessageBoxPadding = {
  padding: {
    xs: "0px 10px 0px 10px",
    sm: "0px 10px 0px 10px",
    md: "0px 10px 0px 10px",
    lg: "0px 10px 0px 10px",
  },
  marginTop: {
    xs: "20px",
    sm: "20px",
    md: "25px",
    lg: "25px",
  },
};
export const inputsMargins = {
  marginTop: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
};
export const MessageInputStyle = {
  padding: "10px",
  borderRadius: "10px",
  fontSize: "15px",
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  height: "80px",
  boxSizing: "border-box",
  border: "none",
  borderColor: "#FF1F2F",
  // backgroundColor: "#FF1F2F",
  backgroundColor: "#fff",
  color: "#FF1F2F",
};
export const FooterBlackArrow = {
  width: "30px",
  height: "30px",
  position: "absolute",
  left: {
    xs: "235px",
    sm: "235px",
    md: "235px",
    lg: "235px",
  },
  top: "5px",
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)", // This will make the image 20% larger on hover
  },
};
export const FootersocialIconsAlignment = {
  display: "flex",
  flexDirection: "row",
  marginTop: "5px",
};
export const FooterSocialIconsSize = {
  width: "14px",
  height: "14px",
  margin: "5px",
};
export const FooterCopyrightAlignment = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "30px",
};
export const FooterCopyrightStyle = {
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "13px",
    lg: "13px",
  },
  fontWeight: {
    xs: "400",
    sm: "400",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
  alignItems: "center",
  color:"white"
};
export const FooterQuickLinksMainAlignment = {
  display: "flex",
  justifyContent: {
    xs: "flex-start",
    sm: "flex-start",
    md: "center",
    lg: "center",
  },
  alignItems: {
    xs: "flex-start",
    sm: "flex-start",
    md: "center",
    lg: "center",
  },
  // margin: "0px 10px 0px 10px",
  marginLeft:{
    xs:"0px",
    sm:"0px",
    md:"-80px",
    lg:"-80px"
  },
  paddingLeft:{
    xs:"10px",
    sm:"0px",
    md:"0px",
    lg:"0px",
  }
};
export const FooterQuicklinksStyle = {
  marginTop: {
    xs: "1px",
    sm: "5px",
    md: "5px",
    lg: "5px",
  },
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "15px",
    lg: "15px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  lineHeight: {
    xs: "25px",
    sm: "25px",
    md: "20px",
    lg: "20px",
  },
  color: "#fff",
  cursor: "pointer",
  fontFamily: "Inter, sans-serif",
};

// Full Card
export const FullcardImage = {
  width: {
    xs: "95%",
    sm: "95%",
    md: "530px",
    lg: "530px",
  },
  marginLeft: {
    xs: "10px",
    sm: "10px",
    md: "0px",
    lg: "0px",
  },
  marginRight: {
    xs: "10px",
    sm: "10px",
    md: "0px",
    lg: "0px",
  },
  display: "flex",
  borderRadius: {
    xs: "15px",
    sm: "15px",
    md: "10px",
    lg: "10px",
  },
  border: {
    xs: "1px solid red",
    sm: "1px solid red",
    md: "1px solid red",
    lg: "1px solid red",
  },
};

export const FullcardImageMargins = {
  marginTop: {
    xs: "10px",
    sm: "10px",
    md: "60px",
    lg: "60px",
  },
  marginBottom: {
    xs: "10px",
    sm: "10px",
    md: "60px",
    lg: "60px",
  },
};

export const FullcardSubcategoryName = {
  fontSize: {
    xs: "17px",
    sm: "17px",
    md: "20px",
    lg: "20px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  display: "flex",
  alignContent: {
    xs: "center",
    sm: "center",
    md: "flex-end",
    lg: "flex-end",
  },
  alignItems: {
    xs: "center",
    sm: "center",
    md: "flex-start",
    lg: "flex-start",
  },
  justifyContent: {
    xs: "flex-start",
    sm: "flex-start",
    md: "flex-start",
    lg: "flex-start",
  },
  lineHeight: {
    xs: "20px",
    sm: "12px",
    md: "12px",
    lg: "12px",
  },
  marginTop: {
    xs: "5px",
    sm: "5px",
    md: "30px",
    lg: "30px",
  },
  color: "rgb(79, 74, 76)",
  // color: "#FF1F2F",
  marginLeft: {
    xs: "10px",
    sm: "10px",
    md: "0px",
    lg: "0px",
  },
};

export const FullcardProductName = {
  fontSize: {
    xs: "25px",
    sm: "25px",
    md: "30px",
    lg: "30px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "500",
    lg: "500",
  },
  display: "flex",
  alignContent: {
    xs: "center",
    sm: "center",
    md: "flex-end",
    lg: "flex-end",
  },
  alignItems: {
    xs: "center",
    sm: "center",
    md: "flex-start",
    lg: "flex-start",
  },
  justifyContent: {
    xs: "flex-start",
    sm: "flex-start",
    md: "flex-start",
    lg: "flex-start",
  },
  lineHeight: {
    xs: "30px",
    sm: "30px",
    md: "34px",
    lg: "34px",
  },
  marginTop: {
    xs: "5px",
    sm: "5px",
    md: "15px",
    lg: "15px",
  },
  marginLeft: {
    xs: "10px",
    sm: "10px",
    md: "0px",
    lg: "0px",
  },
  color: "#FF1F2F",
};

export const UniqueCodeStaticText = {
  fontSize: {
    xs: "13px",
    sm: "13px",
    md: "14px",
    lg: "14px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  display: "flex",
  color: "rgb(79, 74, 76)",
};
export const UniqueCodeDynamicText = {
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
  },
  marginTop: {
    xs: "0px",
    sm: "0px",
    md: "-1px",
    lg: "-1px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "700",
  display: "flex",
};

export const FullcardProductDescription = {
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
  },
  marginTop: {
    xs: "0px",
    sm: "0px",
    md: "-1px",
    lg: "-1px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "500",
  display: "flex",
  lineHeight: {
    xs: "17px",
    sm: "17px",
    md: "20px",
    lg: "20px",
  },
  marginLeft: {
    xs: "10px",
    sm: "10px",
    md: "0px",
    lg: "0px",
  },
};

export const RequestCallButton = {
  marginTop: {
    xs: "15px",
    sm: "15px",
    md: "20px",
    lg: "20px",
  },
  marginBottom: {
    xs: "10px",
    sm: "10px",
    md: "20px",
    lg: "20px",
  },
  display: "flex",
  justifyContent: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "center",
  },
  alignItems: "center",
  width: {
    xs: "160px",
    sm: "160px",
    md: "170px",
    lg: "170px",
  },
  height: {
    xs: "40px",
    sm: "40px",
    md: "40px",
    lg: "40px",
  },
  backgroundColor: "#FF1F2F",
  ":hover": {
    backgroundColor: "#e0000f",
  },
  borderRadius: "20px",
  cursor: "pointer",
};

export const AddtocartButtonDesign = {
  backgroundColor: "#FF1F2F",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderRadius: "20px",
  height: "40px",
  ":hover": {
    backgroundColor: "#e0000f",
  },
  boxShadow: "4",
  marginLeft: "20px",
  cursor: "pointer",
  width: "110px",
};

export const RequestCallButtonText = {
  fontSize: {
    xs: "13px",
    sm: "13px",
    md: "15px",
    lg: "15px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

export const RequestCallButtonAlign = {
  display: "flex",
  justifyContent: {
    xs: "flex-start",
    sm: "flex-start",
    md: "flex-start",
    lg: "flex-start",
  },
  paddingLeft: {
    xs: "10px",
    sm: "10px",
    md: "0px",
    lg: "0px",
  },
};

export const FourAlignment = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "15px",
  paddingLeft: {
    xs: "10px",
    sm: "10px",
    md: "0px",
    lg: "0px",
  },
  paddingRight: {
    xs: "10px",
    sm: "10px",
    md: "0px",
    lg: "0px",
  },
};

export const CategoryStaticText = {
  fontSize: {
    xs: "13px",
    sm: "13px",
    md: "14px",
    lg: "14px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  display: "flex",
  color: "rgb(79, 74, 76)",
};

export const CategoryDynamicText = {
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
  },
  marginTop: {
    xs: "5px",
    sm: "5px",
    md: "5px",
    lg: "5px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "700",
  display: "flex",
};

export const SizeStaticText = {
  fontSize: {
    xs: "13px",
    sm: "13px",
    md: "14px",
    lg: "14px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  display: "flex",
  color: "rgb(79, 74, 76)",
};

export const SizeDynamicText = {
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
  },
  marginTop: {
    xs: "5px",
    sm: "5px",
    md: "5px",
    lg: "5px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "700",
  display: "flex",
};

export const IlluminationStaticText = {
  fontSize: {
    xs: "13px",
    sm: "13px",
    md: "14px",
    lg: "14px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  display: "flex",
  color: "rgb(79, 74, 76)",
};

export const IlluminationDynamicText = {
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
  },
  marginTop: {
    xs: "5px",
    sm: "5px",
    md: "5px",
    lg: "5px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "700",
  display: "flex",
};

export const TotalAreaStaticText = {
  fontSize: {
    xs: "13px",
    sm: "13px",
    md: "14px",
    lg: "14px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  display: "flex",
  color: "rgb(79, 74, 76)",
};

export const TotalAreaDynamicText = {
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
  },
  marginTop: {
    xs: "5px",
    sm: "5px",
    md: "5px",
    lg: "5px",
  },
  fontFamily: "Inter, sans-serif",
  fontWeight: "700",
  display: "flex",
};

// POP-UP STYLES
export const PopupPage = {
  position: "fixed",
  left: "0px",
  top: "0px",
  backgroundColor: "rgba(0,0,0,0.9)",
  width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
  height: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
  display: "flex",
  justifyContent: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "center",
  },
  alignItems: { xs: "center", sm: "center", md: "center", lg: "center" },
  zIndex: "99",
};

export const PopupContentAlignment = {
  position: "relative",
  backgroundColor: "#fff",
  border: "1px solid #999",
  borderRadius: "4px",
  marginTop: { xs: "10px", sm: "10px", md: "60px", lg: "60px" },
  overflow: "auto",
  width: { xs: "95%", sm: "95%", md: "500px", lg: "500px" },
  height: { xs: "530px", sm: "530px", md: "470px", lg: "470px" },
};

export const CloseButtonStyle = {
  width: {
    xs: "18px",
    sm: "18px",
    md: "20px",
    lg: "20px",
  },
  height: {
    xs: "18px",
    sm: "18px",
    md: "20px",
    lg: "20px",
  },
  cursor: "pointer",
};

export const RequestCallText = {
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "28px",
    lg: "28px",
  },
  fontWeight: "700",
  fontFamily: "Inter, sans-serif",
  // color: "#FF1F2F",
  color: "#000",
};

export const PopupNameInputField = {
  padding: "10px",
  borderRadius: "10px",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  height: "45px",
  boxSizing: "border-box",
  border: "none",
  // borderColor: "#FF1F2F",
  // backgroundColor: "#FF1F2F",
  color: "white",
};
export const PopupEmailField = {
  padding: "10px",
  borderRadius: "10px",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  height: "45px",
  boxSizing: "border-box",
  border: "none",
  // borderColor: "#FF1F2F",
  // backgroundColor: "#FF1F2F",
  color: "white",
};
export const PopupPhoneField = {
  padding: "10px",
  borderRadius: "10px",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  height: "45px",
  boxSizing: "border-box",
  border: "none",
  borderColor: "#FF1F2F",
  backgroundColor: "#FF1F2F",
  color: "white",
};

export const PopUpRequestMessage = {
  padding: "10px",
  borderRadius: "10px",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  height: "70px",
  boxSizing: "border-box",
  border: "none",
  // borderColor: "#FF1F2F",
  // backgroundColor: "#FF1F2F",
  color: "white",
};

// Auth

// SignUP
export const AuthFormSignUpInputs = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  width: "100%",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  outline: "none",
  boxShadow: "none",
  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
  color: "black",
};

export const AuthFormSignUpInputsForMobile = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  width: "100%",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  outline: "none",
  boxShadow: "none",
  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
  color: "black",
  // backgroundColor:"green",
};
export const AuthFormSignUpInputsAlignNameAndCity = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
    md: "row",
    lg: "row",
  },
  justifyContent: {
    // xs: "center",
    sm: "space-between",
    md: "space-between",
    lg: "space-between",
  },
  alignItems: {
    // xs: "center",
    sm: "none",
    md: "none",
    lg: "none",
  },
  marginTop: {
    xs: "20px",
    sm: "30px",
    md: "5px",
    lg: "5px",
  },
};
export const AuthFormSignUpInputsAlignPhoneAndEmail = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
    md: "row",
    lg: "row",
  },
  justifyContent: {
    // xs: "center",
    sm: "space-between",
    md: "space-between",
    lg: "space-between",
  },
  alignItems: {
    // xs: "center",
    sm: "none",
    md: "none",
    lg: "none",
  },
};

export const AuthFormSignUpInputsAlignStateandCity = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
    md: "row",
    lg: "row",
  },
  justifyContent: {
    // xs: "center",
    sm: "space-between",
    md: "space-between",
    lg: "space-between",
  },
  alignItems: {
    // xs: "center",
    sm: "none",
    md: "none",
    lg: "none",
  },
};

export const PasswordInputAlign = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "column",
    lg: "column",
  },
  justifyContent: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "center",
  },

  marginTop: {
    xs: "0px",
    sm: "0px",
    md: "0px",
    lg: "0px",
  },
};

// LogIN
export const AuthFormLoginInputsAlign = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "column",
    lg: "column",
  },
  justifyContent: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "center",
  },

  marginTop: {
    xs: "20px",
    sm: "30px",
    md: "5px",
    lg: "5px",
  },
};

export const AuthModalLoginstyle = {
  position: "absolute",
  top: "50%",
  // left: "50%",
  right: {
    xs: "-40%",
    sm: "-40%",
    md: "-20%",
    lg: "-20%",
  },
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    sm: "85%",
    md: "45%",
    lg: "40%",
  },
  height: {
    xs: "79%",
    sm: "85%",
    md: "99%",
    lg: "99%",
  },
  backgroundImage: `url(${weSeeThingsBgImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  boxShadow: 24,
  border: "none",
  borderRadius: {
    xs: "5px",
    sm: "",
  },
  overflow: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const AuthModalstyle = {
  position: "absolute",
  top: "50%",
  right: {
    xs: "-40%",
    sm: "-40%",
    md: "-20%",
    lg: "-20%",
  },

  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    sm: "85%",
    md: "45%",
    lg: "40%",
  },
  height: {
    xs: "90%",
    sm: "80%",
    md: "90%",
    lg: "99%",
  },
  backgroundImage: `url(${weSeeThingsBgImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  boxShadow: 24,
  border: "none",
  borderRadius: {
    xs: "5px",
    sm: "",
  },
  overflow: "auto",
};

export const CartModalstyle = {
  position: "absolute",
  top: "50%",
  right: {
    xs: "-40%",
    sm: "-40%",
    md: "-15%",
    lg: "-15%",
  },

  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    sm: "85%",
    md: "30.5%",
    lg: "30.5%",
  },
  height: {
    xs: "80%",
    sm: "85%",
    md: "99%",
    lg: "99%",
  },
  backgroundImage: `url(${weSeeThingsBgImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  boxShadow: 24,
  border: "none",
  borderRadius: {
    xs: "5px",
    sm: "",
  },
  overflow: "auto",
};

export const FillUpFor = {
  fontSize: "18px",
  fontWeight: "300",
  color: "#FF1F2F",
  fontFamily: "Inter, sans-serif",
  letterSpacing: "10px",
  textAlign: "center",
  marginBottom: "-10px",
};

export const RegistrationAndLoginText = {
  fontSize: "35px",
  fontWeight: "300",
  color: "#FF1F2F",
  fontFamily: "Inter, sans-serif",
  letterSpacing: "3px",
  textAlign: "center",
};

export const AuthFormImageStyle = {
  width: {
    xs: "85%",
    sm: "100%",
  },
  borderRadius: "10px",
};

export const AuthFormImageAlignment = {
  display: "flex",
  justifyContent: {
    xs: "center",
    sm: "none",
    md: "none",
    lg: "none",
  },

  marginTop: {
    xs: "15px",
    sm: "15px",
    md: "10px",
    lg: "10px",
  },
};

export const InputsLeftandRightPadding = {
  padding: {
    xs: "0px 30px 0px 30px",
    sm: "0px 0px 0px 0px",
    md: "0px 0px 0px 0px",
    lg: "0px 0px 0px 0px",
  },
};

export const RegistrationSubmitButtonAlign = {
  padding: {
    xs: "0px 30px 0px 30px",
    sm: "0px 0px 0px 0px",
    md: "0px 0px 0px 0px",
    lg: "0px 0px 0px 0px",
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: {
    xs: "20px",
    sm: "20px",
    md: "30px",
    lg: "30px",
  },
};

export const RegistrationSubmitbuttonStyle = {
  width: {
    xs: "100%",
    sm: "100%",
    md: "300px",
    lg: "300px",
  },
  height: {
    xs: "40px",
    sm: "40px",
    md: "40px",
    lg: "40px",
  },
  borderRadius: "5px",
  border: "1.5px solid #000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: {
    xs: "10px",
  },
  cursor: "pointer",
  transition: "transform 0.3s ease", // Smooth transition for zoom effect
  "&:hover": {
    transform: "scale(1.01)", // Slight zoom effect on hover
  },
  // backgroundColor:"red"
};

export const AlreadyAndHaveNot = {
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "20px",
  fontFamily: "Inter, sans-serif",
  fontWeight: "400",
  marginTop: "15px",
};

export const AuthButtonText = {
  textAlign: "center",
  color: "#000",
  fontWeight: "500",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
};

export const RegistrationStateAndCityStyle = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  width: "330px",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  color: "grey",
  outline: "none",
  fontWeight: "500",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
};
export const RegistrationStateAndCityStyleForMobile = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  width: "100%",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  fontFamily: "Inter, sans-serif",
  color: "grey",
  outline: "none",
  fontSize: "14px",
  fontWeight: "500",
};

export const LoginwithGmailText = {
  textAlign: "center",
  color: "#000",
  fontWeight: "500",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  marginLeft: "30px",
};

export const GooglelogoStyle = {
  width: {
    xs: "25px",
    sm: "25px",
    md: "25px",
    lg: "25px",
  },
  height: {
    xs: "25px",
    sm: "25px",
    md: "25px",
    lg: "25px",
  },
  margin: "0px 10px 0px 10px",
};

export const loginwithGmailbuttonAlign = {
  padding: {
    xs: "0px 30px 0px 30px",
    sm: "0px 0px 0px 0px",
    md: "0px 0px 0px 0px",
    lg: "0px 0px 0px 0px",
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: {
    xs: "0px",
    sm: "0px",
    md: "0px",
    lg: "0px",
  },
};

export const AuthFormButtonStyle = {
  width: {
    xs: "100%",
    sm: "100%",
    md: "300px",
    lg: "300px",
  },
  height: {
    xs: "40px",
    sm: "40px",
    md: "40px",
    lg: "40px",
  },
  borderRadius: "5px",
  border: "1.5px solid #000",
  display: "flex",
  alignItems: "center",
};

export const ortextStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: {
    xs: "5px",
    sm: "5px",
    md: "5px",
    lg: "5px",
  },
  marginBottom: {
    xs: "5px",
    sm: "5px",
    md: "5px",
    lg: "5px",
  },
};

export const LoginpageSubmitButtonAlign = {
  padding: {
    xs: "0px 30px 0px 30px",
    sm: "0px 0px 0px 0px",
    md: "0px 0px 0px 0px",
    lg: "0px 0px 0px 0px",
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: {
    xs: "30px",
    sm: "30px",
    md: "20px",
    lg: "20px",
  },
  transition: "transform 0.3s ease", // Smooth transition for zoom effect
  "&:hover": {
    transform: "scale(1.01)", // Slight zoom effect on hover
  },
};

export const LoginpageSubmitButtonStyle = {
  width: {
    xs: "100%",
    sm: "100%",
    md: "300px",
    lg: "300px",
  },
  height: {
    xs: "40px",
    sm: "40px",
    md: "40px",
    lg: "40px",
  },
  borderRadius: "5px",
  border: "1.5px solid #000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

export const ContactFormSignUpInputs = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  color: "black",
  fontWeight: "500",
  width: "45%",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  outline: "none",
  boxShadow: "none",
  fontSize: "14px",
  fontFamily: "Inter, sans-serif",
  // backgroundColor:"green",
};

export const ContactFormSignUpInputsForMobile = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  width: "100%",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  outline: "none",
  boxShadow: "none",
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
  color: "black",
  // backgroundColor:"green",
};
export const ContactFormSignUpMessage = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  width: "100%",
  color: "black",
  fontWeight: "500",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  outline: "none",
  boxShadow: "none",
  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
  // backgroundColor:"green",
};

export const ContactformStateAndCityStyleForMobile = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  width: "100%",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  color: "grey",
  outline: "none",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontWeight: "500",
};

export const ContactformStateAndCityStyleForDesktop = {
  borderBottom: "1px solid #000",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  width: "308px",
  height: "45px",
  borderRadius: "0px",
  backgroundColor: "transparent",
  color: "grey",
  outline: "none",
  fontFamily: "Inter, sans-serif",
  fontSize: "14px",
  fontWeight: "500",
};

// conatct us styles
export const googlemapimagestyle = {
  width: "100%",
  height: "90%",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
  marginTop: {
    xs: "20px",
    sm: "0px",
    md: "0px",
    lg: "0px ",
  },
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.009)",
  },
};

export const corporateofficetext = {
  fontSize: {
    xs: "26px",
    sm: "26px",
    md: "28px",
    lg: "28px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  color: "#FF1F2F",
  fontFamily: "Inter, sans-serif",
};

export const maineaddresstext = {
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "16px",
    lg: "16px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  color: "#000",
  lineHeight: {
    xs: "23px",
    sm: "23px",
    md: "23px",
    lg: "23px",
  },
  fontFamily: "Inter, sans-serif",
};

export const callIconAndNumber = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const calliconStyle = {
  width: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
  height: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
};

export const numberStyle = {
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "16px",
    lg: "16px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  color: "#000",
  lineHeight: {
    xs: "23px",
    sm: "23px",
    md: "23px",
    lg: "23px",
  },
  marginLeft: {
    xs: "10px",
    sm: "10px",
    md: "10px",
    lg: "10px",
  },
  fontFamily: "Inter, sans-serif",
};

export const loactionmailstyle = {
  width: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
  height: {
    xs: "20px",
    sm: "20px",
    md: "20px",
    lg: "20px",
  },
};

export const emailAddressStyle = {
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "16px",
    lg: "16px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  color: "#000",
  lineHeight: {
    xs: "23px",
    sm: "23px",
    md: "23px",
    lg: "23px",
  },
  marginLeft: {
    xs: "10px",
    sm: "10px",
    md: "10px",
    lg: "10px",
  },
  fontFamily: "Inter, sans-serif",
};

export const ContactusmainMargin = {
  marginTop: {
    xs: "20px",
    sm: "20px",
    md: "60px",
    lg: "60px",
  },
  marginBottom: {
    xs: "20px",
    sm: "20px",
    md: "60px",
    lg: "60px",
  },
};

// headers modals

export const cartModalcart = {
  fontSize: {
    xs: "26px",
    sm: "26px",
    md: "23px",
    lg: "23px",
  },
  fontSize: {
    xs: "25px",
    sm: "25px",
    md: "24px",
    lg: "24px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
};

export const getstarted = {
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "14px",
    lg: "14px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
};

export const badgeStyle = {
  "& .MuiBadge-badge": {
    backgroundColor: "red",
    color: "white",
    fontSize: "12px",
    marginRight: "15px",
  },
};

export const blackCartLogoDesign = {
  width: "25px",
  height: "25px",
  cursor: {
    xs: "none",
    sm: "none",
    md: "pointer",
    lg: "pointer",
  },
  marginRight: "20px",
};

export const cartItemsboxStyle = {
  flex: 1,
  overflowY: "auto",
  padding: "20px 0",
  marginTop: "100px",
  // backgroundColor:"yellow",
  height: "400px",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "red",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "darkred",
  },
};

export const cartItemImgStyle = {
  width: "80px",
  height: "80px",
  borderRadius: "5px 0px 0px 5px",
};

export const cartItemProductname = {
  fontSize: "12px",
  color: "black",
  fontFamily: "Inter, sans-serif",
  fontWeight: "500",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
};

export const cartIsEmptyStyle = {
  marginTop: "30px",
  fontSize: "20px",
  color: "#FF1F2F",
  fontFamily: "Inter, sans-serif",
  fontWeight: "500",

  textAlign: "center",
};

export const emptyCartImageStyle = {
  width: "200px",
  height: "200px",
  marginRight: "100px",
};

export const EmptyCartImgAndText = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // backgroundColor: "green",
  flexDirection: "column",
};

export const removeCartImg = {
  width: "10px",
  height: "10px",
  cursor: "pointer",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.5)",
  },
};

export const cartitemUniquecode = {
  fontSize: "12px",
  fontweight: "500",
  fontFamily: "Inter, sans-serif",
};

export const cartitemState = {
  fontSize: "12px",
  fontweight: "500",
  fontFamily: "Inter, sans-serif",
};

export const seperationStyle = {
  fontSize: "12px",
  fontweight: "500",
  fontFamily: "Inter, sans-serif",
  color: "grey",
  margin: "0px 5px 0px 5px",
};

export const goToCart = {
  fontSize: {
    xs: "15px",
    sm: "15px",
    md: "15px",
    lg: "15px",
  },
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
  marginLeft: "5px",
};

export const ProceedModalCloseRedButton = {
  width: "15px",
  height: "15px",
  cursor: "pointer",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.03)", // Scale up on hover
  },
};

export const EnterYourDetails = {
  fontSize: {
    xs: "26px",
    sm: "26px",
    md: "23px",
    lg: "23px",
  },
  fontSize: {
    xs: "25px",
    sm: "25px",
    md: "24px",
    lg: "24px",
  },
  fontWeight: {
    xs: "500",
    sm: "500",
    md: "400",
    lg: "400",
  },
  fontFamily: "Inter, sans-serif",
  textAlign: "center",
  marginTop: "15px",
};
