import { combineReducers } from "redux";

import { OutdoorReducer } from "./Outdoor";
import { HomeReducer } from "./Home";
import { BlogReducer } from "./Blog";
import { UtilReducer } from "./Utils";

const rootReducer = combineReducers({
  OutdoorReducer,
  HomeReducer,
  BlogReducer,
  UtilReducer
});

export default rootReducer;
