import React, { useState, useEffect } from "react";

// Material Ui
import {
  Box,
  Grid,
  AppBar,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Menu,
  Typography,
  MenuIcon,
  Button,
  Modal,
  Badge,
  CircularProgress,
} from "../../DesignData/MaterialUi/material-ui";

// IMAGES
import {
  JanusOohLogo,
  DropdownArrow,
  SideDrawerCloseIcon,
  cartimg,
  cartRedArrow,
  imageNotFound,
  blackCartLogo,
  emptyCart,
  cartblackArrow,
} from "../../DesignData/Images/Images";

// toastify
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

// REACT_ROUTER_DOM
import { useNavigate } from "react-router-dom";

// Side Drawer Content
import { SideDrawerContent } from "../../assets/json/newDesign/SideDrawerContent";

// CategoriesDropdown
import { CategoriesDropdown } from "../../assets/json/newDesign/CategoriesDropdown";

// CSS
import "./Header.css";

// styles
import {
  HeaderBGcolor,
  HeaderCategories,
  HeaderCategoriesBox,
  HeaderLogoAlignMent,
  HeaderLogoAndNavigators,
  HeaderLogoStyle,
  HeaderNavigatorsStyle,
  SideDrawerIcon,
  SideDrawerIconsAndTextAlign,
  SideDrawerLogoAndCloseButtonAlign,
  SideDrawerTextStyle,
  SiderDrawerCloseIconStyle,
  SiderDrawerLogoStyle,
  CartModalstyle,
  AuthFormSignUpInputs,
  RegistrationStateAndCityStyle,
  cartModalcart,
  getstarted,
  badgeStyle,
  blackCartLogoDesign,
  cartItemsboxStyle,
  cartItemImgStyle,
  cartItemProductname,
  cartIsEmptyStyle,
  emptyCartImageStyle,
  EmptyCartImgAndText,
  removeCartImg,
  cartitemUniquecode,
  cartitemState,
  seperationStyle,
  goToCart,
  ProceedModalCloseRedButton,
  EnterYourDetails,
} from "../../pages/Styles/Styles";
import { removeFromCart } from "../../redux/actions/Cart";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import types from "../../redux/types";
import { AllCities } from "../../assets/json/AllCities";

const drawerWidth = 240;

const { dispatch } = store;

function Header(props) {
  // state and city------------------------------------------------------
  const [stateData, setStateData] = useState(null);
  const [cityData, setCityData] = useState(null);
  const handleSelectChange = (event) => {
    setSelectedState(event.target.value);
    const cities = AllCities[event.target.value];
    setCityData(cities);
    // fetch(
    //   "https://www.januskoncepts.in/janusoohadmin/api/stateandcity/location.php?state_id=" +
    //     event.target.value
    // )
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     console.log("cityData", responseJson);
    //     setCityData(responseJson);
    //   })
    //   .catch((error) => {
    //     console.error("There was a problem with the fetch operation:", error);
    //   });
  };

  useEffect(() => {
    fetch(
      "https://www.januskoncepts.in/janusoohadmin/api/stateandcity/location.php"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("state and city", responseJson);
        setStateData(responseJson);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  // ------------------------------------------------------

  // // post products
  // const [userName, setUserName] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  // const [userPhone, setUserPhone] = useState("");
  // const [userCompany, setUserCompany] = useState("");
  // const [selectedState, setSelectedState] = useState("");
  // const [selectedCity, setSelectedCity] = useState("");
  // const [errors, setErrors] = useState({});

  // const handleSubmit = async () => {
  //   // Validate inputs
  //   const newErrors = {};
  //   if (!userName) newErrors.userName = "Name is required";
  //   if (!userEmail) newErrors.userEmail = "Email is required";
  //   if (!userPhone) newErrors.userPhone = "Phone number is required";
  //   if (!userCompany) newErrors.userCompany = "Company name is required";
  //   if (!selectedState) newErrors.userState = "State is required";
  //   if (!selectedCity) newErrors.userCity = "City is required";

  //   setErrors(newErrors);

  //   // If there are errors, do not proceed
  //   if (Object.keys(newErrors).length > 0) return;

  //   // Get data from localStorage
  //   const userData = JSON.parse(localStorage.getItem("CartItems")) || [];

  //   // Prepare data to be sent to the API
  //   const postData = {
  //     user_name: userName,
  //     user_email: userEmail,
  //     user_phone: userPhone,
  //     user_company: userCompany,
  //     user_state: selectedState,
  //     user_city: selectedCity,
  //     products: userData.map((item) => ({
  //       product_id: item.id,
  //       product_name: item.product_name,
  //       product_city_name: item.city_name,
  //       product_state_name: item.state_name,
  //       product_unique_code: item.uniquecode,
  //     })),
  //   };

  //   try {
  //     const response = await fetch(
  //       "https://www.januskoncepts.in/janusoohadmin/api/cart/postcart.php",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(postData),
  //       }
  //     );

  //     if (response.ok) {
  //       // Clear local storage and show success message
  //       localStorage.removeItem("CartItems");
  //       Toastify({
  //         text: "Submitted successfully!",
  //         duration: 3000,
  //         close: true,
  //         gravity: "top",
  //         position: "center",
  //         backgroundColor: "#4CAF50",
  //       }).showToast();

  //       // Clear the form inputs and select fields
  //       setUserName("");
  //       setUserEmail("");
  //       setUserPhone("");
  //       setUserCompany("");
  //       setSelectedState("");
  //       setSelectedCity("");

  //       // Close modal
  //       handleCartmodalClose();
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Submission failed:", errorData);
  //       Toastify({
  //         text: "Submission failed!",
  //         duration: 3000,
  //         close: true,
  //         gravity: "top",
  //         position: "right",
  //         backgroundColor: "#FF1F2F",
  //       }).showToast();
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     Toastify({
  //       text: "Network error!",
  //       duration: 3000,
  //       close: true,
  //       gravity: "top",
  //       position: "right",
  //       backgroundColor: "#FF1F2F",
  //     }).showToast();
  //   }
  // };

  // post products
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [errors, setErrors] = useState({});
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true); // Show loader

    // Validate inputs
    const newErrors = {};
    if (!userName) newErrors.userName = "Name is required";
    if (!userEmail) newErrors.userEmail = "Email is required";
    if (!userPhone) newErrors.userPhone = "Phone number is required";
    if (!userCompany) newErrors.userCompany = "Company name is required";
    if (!selectedState) newErrors.userState = "State is required";
    if (!selectedCity) newErrors.userCity = "City is required";

    setErrors(newErrors);

    // If there are errors, do not proceed
    if (Object.keys(newErrors).length > 0) {
      setIsSubmitting(false); // Hide loader
      return;
    }

    // Get data from localStorage
    const userData = JSON.parse(localStorage.getItem("CartItems")) || [];

    // Prepare data to be sent to the API
    const postData = {
      user_name: userName,
      user_email: userEmail,
      user_phone: userPhone,
      user_company: userCompany,
      user_state: selectedState,
      user_city: selectedCity,
      products: userData.map((item) => ({
        product_id: item.id,
        product_name: item.product_name,
        product_city_name: item.city_name,
        product_state_name: item.state_name,
        product_unique_code: item.uniquecode,
      })),
    };

    try {
      const response = await fetch(
        "https://www.januskoncepts.in/janusoohadmin/api/cart/postcart.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        // Clear local storage and show success message
        localStorage.removeItem("CartItems");
        dispatch({
          type: types.UPDATE_CART,
          payload: null,
        });
        Toastify({
          text: "Submitted successfully!",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "center",
          backgroundColor: "#4CAF50",
        }).showToast();

        // Clear the form inputs and select fields
        setUserName("");
        setUserEmail("");
        setUserPhone("");
        setUserCompany("");
        setSelectedState("");
        setSelectedCity("");
        handleProceedModalClose();
      } else {
        const errorData = await response.json();
        console.error("Submission failed:", errorData);
        Toastify({
          text: "Submission failed!",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "#FF1F2F",
        }).showToast();
      }
    } catch (error) {
      console.error("Error:", error);
      Toastify({
        text: "Network error!",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#FF1F2F",
      }).showToast();
    } finally {
      setIsSubmitting(false); // Hide loader
    }
  };

  // modal open and close------------------------------------------------------
  const [opencartmodal, setOpencartmodal] = useState(false);
  const [openProceedModal, setOpenProceedModal] = useState(false);

  const { cartItems } = useSelector((state) => state.UtilReducer);

  const handleProceedModalClose = () => setOpenProceedModal(false);

  const handleCartmodalOpen = () => {
    setOpencartmodal(true);
  };

  const handleProceedClick = () => {
    setOpencartmodal(false);
    setOpenProceedModal(true);
  };

  const handleCartmodalClose = () => setOpencartmodal(false);

  // counted no on badge------------------------------------------------------
  const [cartCount, setCartCount] = useState(0);

  const updateCartCount = () => {
    const cart = JSON.parse(localStorage.getItem("CartItems")) || [];
    setCartCount(cart.length);
  };

  // get data from local storage------------------------------------------------------
  // const [cartItems, setCartItems] = useState([]);

  // useEffect(() => {
  //   const cart = JSON.parse(localStorage.getItem("CartItems"));
  //   setCartItems(cart ? cart : []);
  // }, []);

  // useEffect(() => {
  //   updateCartCount(cartItems);
  // }, []);

  // delete product  from localstorage------------------------------------------------------
  const handleDelete = (productId) => {
    removeFromCart(productId);
  };

  // const handleDelete = (productId) => {
  //   const cart = JSON.parse(localStorage.getItem("CartItems")) || [];
  //   console.log("DELETE CART ITEM", cart, productId);
  //   const updatedCart = cart.filter((item) => item?.id !== productId);
  //   localStorage.setItem("CartItems", JSON.stringify(updatedCart));
  //   setCartItems(updatedCart);
  // };

  // dropdwon
  const [anchorEl, setAnchorEl] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // dropdown
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // Mobile side bar navigator
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        backgroundColor: "#fff",
        height: "100%",
        position: "relative",
      }}
    >
      <Box sx={SideDrawerLogoAndCloseButtonAlign}>
        {/* Logo */}
        <Box
          component="img"
          sx={SiderDrawerLogoStyle}
          alt="janusLogo"
          src={JanusOohLogo}
          onClick={() => navigate("/")}
        />
        {/* CloseIcon */}
        <Box
          component="img"
          src={SideDrawerCloseIcon}
          sx={SiderDrawerCloseIconStyle}
        />
      </Box>

      <Box sx={{ marginTop: "30px" }}>
        {/* Home */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {SideDrawerContent.map((item, index) => (
            <Box
              sx={SideDrawerIconsAndTextAlign}
              onClick={() => navigate(item.link)}
            >
              <Box component="img" src={item.image} style={SideDrawerIcon} />
              <Box sx={{ marginLeft: "15px" }}>
                <Typography sx={SideDrawerTextStyle}>{item.title}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );

  const navigate = useNavigate();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={HeaderBGcolor} elevation={0}>
        <Toolbar>
          {/* Header Logo and navigation texts */}
          <Box sx={HeaderLogoAndNavigators}>
            {/* Janus Logo Desktop */}
            <Box sx={HeaderLogoAlignMent}>
              <Box
                component="img"
                sx={HeaderLogoStyle}
                alt="janusLogo"
                src={JanusOohLogo}
                onClick={() => navigate("/")}
              />
            </Box>

            {/* headers navigators */}
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: {
                    xs: "0px",
                    sm: "0px",
                    md: "40px",
                    lg: "40px",
                  },
                }}
              >
                {/* Home*/}
                <Box>
                  <Typography
                    sx={HeaderNavigatorsStyle}
                    onClick={() => navigate("/")}
                  >
                    HOME
                  </Typography>
                </Box>

                {/* About us */}
                <Box sx={{ paddingLeft: "30px" }}>
                  <Typography
                    sx={HeaderNavigatorsStyle}
                    onClick={() => navigate("/about-us/")}
                  >
                    ABOUT US
                  </Typography>
                </Box>

                {/* Our Services - Dropdown starts */}
                <Box sx={{ paddingLeft: "30px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={HeaderNavigatorsStyle}
                        onMouseOver={handleOpenMenu}
                      >
                        OUR SERVICES
                      </Typography>
                    </Box>

                    {/* DropdownArrow image */}
                    <Box
                      component="img"
                      src={DropdownArrow}
                      style={{
                        width: "10px",
                        height: "5px",
                        marginLeft: "5px",
                        marginBottom: "2px",
                      }}
                    />
                  </Box>

                  <Menu
                    sx={{ marginTop: "22px" }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    MenuListProps={{ onMouseLeave: handleCloseMenu }}
                  >
                    <Box sx={{ width: "100%", borderRadius: "0px" }}>
                      {CategoriesDropdown.map((item, index) => (
                        <Box
                          onClick={() => navigate(item.link)}
                          index={item.index}
                          sx={HeaderCategoriesBox}
                        >
                          <Typography sx={HeaderCategories}>
                            {item.title}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Menu>
                </Box>

                {/* Blogs */}
                <Box sx={{ paddingLeft: "30px" }}>
                  <Typography
                    sx={HeaderNavigatorsStyle}
                    onClick={() => navigate("/blogs/")}
                  >
                    BLOGS
                  </Typography>
                </Box>

                {/* Contact us */}
                <Box sx={{ paddingLeft: "30px" }}>
                  <Typography
                    sx={HeaderNavigatorsStyle}
                    onClick={() => navigate("/contact-us/")}
                  >
                    CONTACT US
                  </Typography>
                  {/* </Button> */}
                </Box>

                {/* cart logo */}
                <Box sx={{ paddingLeft: "30px" }}>
                  {" "}
                  <Badge
                    badgeContent={cartItems ? cartItems.length : 0} // Show the cart count
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "red",
                        color: "white",
                        fontSize: "12px",
                        marginRight: "15px",
                      },
                    }}
                    overlap="rectangular"
                  >
                    <Box
                      component="img"
                      src={cartimg}
                      sx={{
                        width: "25px",
                        height: "25px",
                        cursor: {
                          xs: "none",
                          sm: "none",
                          md: "pointer",
                          lg: "pointer",
                        },
                        marginRight: "20px",
                      }}
                      onClick={handleCartmodalOpen}
                    />
                  </Badge>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Hamburger */}
          <Box
            sx={{ paddingRight: "20px" }}
            display={{ md: "none", lg: "none" }}
          >
            {" "}
            <Badge
              badgeContent={cartItems ? cartItems.length : 0} // Show the cart count
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "red",
                  color: "white",
                  fontSize: "12px",
                  marginRight: "15px",
                },
              }}
              overlap="rectangular"
            >
              <Box
                component="img"
                src={cartimg}
                sx={{
                  width: "25px",
                  height: "25px",
                  cursor: {
                    xs: "none",
                    sm: "none",
                    md: "pointer",
                    lg: "pointer",
                  },
                  marginRight: "20px",
                }}
                display={{ md: "none", lg: "none" }}
                onClick={handleCartmodalOpen}
              />
            </Badge>
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" }, marginRight: "0px" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Cart modal */}
      <Modal
        open={opencartmodal}
        onClose={handleCartmodalClose}
        sx={{ border: "none", outline: "none" }}
      >
        <Grid container sx={CartModalstyle}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ outline: "none" }}>
            {/* TOP BOX */}
            <Box
              sx={{
                height: "140px",
                width: "100%",
                position: "fixed",
                // backgroundColor:"yellow"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: {
                    xs: "15px",
                    sm: "15px",
                    md: "20px",
                    lg: "20px",
                  },
                  padding: {
                    xs: "0px 20px 0px 20px",
                    sm: "0px 20px 0px 20px",
                    md: "0px 20px 0px 20px",
                    lg: "0px 20px 0px 20px",
                  },
                }}
              >
                {/* Arrow */}
                <Box>
                  <Box
                    component="img"
                    src={cartRedArrow}
                    sx={{
                      width: {
                        xs: "18px",
                        sm: "18px",
                        md: "18px",
                        lg: "18px",
                      },
                      height: {
                        xs: "15px",
                        sm: "15px",
                        md: "17px",
                        lg: "17px",
                      },
                      marginTop: {
                        xs: "10px",
                      },
                      cursor: "pointer",
                    }}
                    onClick={handleCartmodalClose}
                  />
                </Box>
                {/* cart text */}
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginLeft: "10px",
                    }}
                  >
                    <Box>
                      <Typography sx={cartModalcart}>Cart</Typography>
                      <Typography sx={getstarted}>
                        Get Started & grab best offer!
                      </Typography>
                    </Box>

                    <Badge
                      badgeContent={cartItems ? cartItems.length : 0} // Show the cart count
                      sx={badgeStyle}
                      overlap="rectangular"
                    >
                      <Box
                        component="img"
                        src={blackCartLogo}
                        sx={blackCartLogoDesign}
                        onClick={handleCartmodalOpen}
                      />
                    </Badge>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Middle */}
            <Box sx={cartItemsboxStyle}>
              {cartItems && cartItems.length > 0 ? (
                cartItems.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "10px 20px 10px 20px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Box
                        component="img"
                        src={item?.image ? item.image : imageNotFound}
                        sx={cartItemImgStyle}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                          justifyContent: "space-between",
                          margin: "0px 5px 0px 10px",
                        }}
                      >
                        <Typography sx={cartItemProductname}>
                          {item?.product_name}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {/* city */}
                          <Box>
                            <Typography sx={cartitemState}>
                              City:{" "}
                              <span style={{ color: "red" }}>
                                {item?.city_name}
                              </span>
                            </Typography>
                          </Box>

                          {/* Seperation */}
                          <Box>
                            <Typography sx={seperationStyle}>|</Typography>
                          </Box>

                          {/* state */}
                          <Box>
                            <Typography sx={cartitemState}>
                              State:{" "}
                              <span style={{ color: "red" }}>
                                {item?.state_name.charAt(0).toUpperCase() +
                                  item?.state_name.slice(1).toLowerCase()}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        {/* uniqueCode */}
                        <Typography sx={cartitemUniquecode}>
                          Code:{" "}
                          <span style={{ color: "red" }}>
                            {item?.uniquecode}
                          </span>
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Box
                        component="img"
                        src={SideDrawerCloseIcon}
                        sx={removeCartImg}
                        onClick={() => {
                          handleDelete(item?.id);
                        }}
                      />
                    </Box>
                  </Box>
                ))
              ) : (
                <>
                  <Box sx={EmptyCartImgAndText}>
                    <Box
                      component="img"
                      src={emptyCart}
                      sx={emptyCartImageStyle}
                    />
                    <Typography sx={cartIsEmptyStyle}>
                      Your Cart is Empty!
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            {/* Bottom */}
            {cartItems && cartItems.length > 0 && (
              <Button
                variant="contained"
                sx={{
                  position: "fixed",
                  bottom: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#FF1F2F",
                  "&:hover": { backgroundColor: "#FF1F2F" },
                }}
                onClick={handleProceedClick}
                disabled={cartItems.length === 0} // Disable the button when the cart is empty
              >
                Proceed
              </Button>
            )}
          </Grid>
        </Grid>
      </Modal>

      {/* Proceed modal */}
      <Modal
        open={openProceedModal}
        onClose={handleProceedModalClose}
        sx={{ border: "none", outline: "none" }}
      >
        <Grid container sx={CartModalstyle}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ outline: "none" }}>
            {isSubmitting ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  zIndex: 1000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={60} />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: {
                      xs: "0px 10px 0px 10px",
                      sm: "0px 10px 0px 10px",
                      md: "0px 10px 0px 10px",
                      lg: "0px 10px 0px 10px",
                    },
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "5px",
                      lg: "5px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleProceedModalClose(); // Close proceed modal
                      handleCartmodalOpen(); // Open cart modal
                    }}
                  >
                    <Box
                      component="img"
                      src={cartblackArrow}
                      sx={{
                        width: {
                          xs: "12px",
                          sm: "12px",
                          md: "13px",
                          lg: "13px",
                        },
                        height: {
                          xs: "12px",
                          sm: "12px",
                          md: "12px",
                          lg: "12px",
                        },
                      }}
                    />
                    <Typography sx={goToCart}>Go to cart</Typography>
                  </Box>

                  <Box
                    component="img"
                    src={SideDrawerCloseIcon}
                    sx={ProceedModalCloseRedButton}
                    onClick={handleProceedModalClose}
                  />
                </Box>
                {/* Content for the new modal */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: {
                      xs: "0px 20px 0px 20px",
                      sm: "0px 20px 0px 20px",
                      md: "0px 20px 0px 20px",
                      lg: "0px 20px 0px 20px",
                    },
                    marginTop: {
                      xs: "15px",
                      sm: "15px",
                      md: "20px",
                      lg: "20px",
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={EnterYourDetails}
                  >
                    Enter Your Details
                  </Typography>
                </Box>
                {/* Add any additional content or buttons here */}
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    marginTop: "20px",
                    height: "400px",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "red",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "darkred",
                    },
                  }}
                >
                  <Box
                    sx={{
                      padding: {
                        xs: "0px 20px 0px 20px",
                        sm: "0px 20px 0px 20px",
                        md: "0px 20px 0px 20px",
                        lg: "0px 30px 0px 30px",
                      },
                    }}
                  >
                    {/* Name */}
                    <Box
                      sx={{
                        marginTop: {
                          xs: "0px",
                          sm: "10px",
                          md: "5px",
                          lg: "5px",
                        },
                      }}
                    >
                      <input
                        name="name"
                        placeholder="Name"
                        autoComplete="off"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        style={AuthFormSignUpInputs}
                        className="custom-input-with-placeholder2"
                      />
                      {errors.userName && (
                        <Typography color="red">{errors.userName}</Typography>
                      )}
                    </Box>

                    {/* Email */}
                    <Box
                      sx={{
                        marginTop: {
                          xs: "15px",
                          sm: "10px",
                          md: "5px",
                          lg: "5px",
                        },
                      }}
                    >
                      <input
                        name="email"
                        placeholder="Email"
                        autoComplete="off"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        style={AuthFormSignUpInputs}
                        className="custom-input-with-placeholder2"
                      />
                      {errors.userEmail && (
                        <Typography color="red">{errors.userEmail}</Typography>
                      )}
                    </Box>

                    {/* Phone */}
                    <Box
                      sx={{
                        marginTop: {
                          xs: "15px",
                          sm: "10px",
                          md: "10px",
                          lg: "10px",
                        },
                      }}
                    >
                      <input
                        name="phone"
                        placeholder="Phone"
                        autoComplete="off"
                        value={userPhone}
                        onChange={(e) => setUserPhone(e.target.value)}
                        style={AuthFormSignUpInputs}
                        className="custom-input-with-placeholder2"
                      />
                      {errors.userPhone && (
                        <Typography color="red">{errors.userPhone}</Typography>
                      )}
                    </Box>

                    {/* Company */}
                    <Box
                      sx={{
                        marginTop: {
                          xs: "15px",
                          sm: "10px",
                          md: "10px",
                          lg: "10px",
                        },
                      }}
                    >
                      <input
                        name="company"
                        placeholder="Company"
                        autoComplete="off"
                        value={userCompany}
                        onChange={(e) => setUserCompany(e.target.value)}
                        style={AuthFormSignUpInputs}
                        className="custom-input-with-placeholder2"
                      />
                      {errors.userCompany && (
                        <Typography color="red">
                          {errors.userCompany}
                        </Typography>
                      )}
                    </Box>

                    {/* DeskTop */}
                    {/* State */}
                    <Box
                      sx={{
                        marginTop: {
                          xs: "5px",
                          sm: "10px",
                          md: "10px",
                          lg: "10px",
                        },
                      }}
                      display={{
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                      }}
                    >
                      <select
                        id="dropdown"
                        value={selectedState}
                        onChange={handleSelectChange}
                        style={{
                          borderBottom: "1px solid #000",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          width: "330px",
                          height: "45px",
                          borderRadius: "0px",
                          backgroundColor: "transparent",
                          color: selectedState ? "black" : "grey", // Change color to black when a state is selected
                          outline: "none",
                          fontWeight: "500",
                          fontSize: "15px",
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        <option value="" disabled>
                          State
                        </option>
                        {stateData &&
                          stateData
                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort states alphabetically
                            .map((item) => (
                              <option key={item.id} value={item.name}>
                                {item.name.charAt(0).toUpperCase() +
                                  item.name.slice(1).toLowerCase()}
                              </option>
                            ))}
                      </select>

                      {errors.userState && (
                        <Typography color="red">{errors.userState}</Typography>
                      )}
                    </Box>

                    {/* City */}
                    <Box
                      sx={{
                        marginTop: {
                          xs: "5px",
                          sm: "10px",
                          md: "10px",
                          lg: "10px",
                        },
                      }}
                      display={{
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                      }}
                    >
                      <select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        style={{
                          borderBottom: "1px solid #000",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          width: "330px",
                          height: "45px",
                          borderRadius: "0px",
                          backgroundColor: "transparent",
                          color: selectedCity ? "black" : "grey", // Change color to black when a city is selected
                          outline: "none",
                          fontWeight: "500",
                          fontSize: "15px",
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        <option value="" disabled>
                          City
                        </option>
                        {cityData &&
                          cityData
                            .sort((a, b) => a.localeCompare(b)) // Sort cities alphabetically
                            .map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                      </select>

                      {errors.userCity && (
                        <Typography color="red">{errors.userCity}</Typography>
                      )}
                    </Box>

                    {/* mobile */}
                    <Box
                      sx={{
                        xs: "0px 20px 0px 20px",
                        sm: "0px 0px 0px 0px",
                        xs: "0px 0px 0px 0px",
                        xs: "0px 0px 0px 0px",
                      }}
                    >
                      {/* state */}
                      <Box
                        sx={{
                          marginTop: {
                            xs: "15px",
                            sm: "10px",
                            md: "10px",
                            lg: "10px",
                          },
                        }}
                        display={{
                          md: "none",
                          lg: "none",
                        }}
                      >
                        <select
                          id="dropdown"
                          value={selectedState}
                          onChange={handleSelectChange}
                          style={{
                            borderBottom: "1px solid #000",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            width: "100%",
                            height: "45px",
                            borderRadius: "0px",
                            backgroundColor: "transparent",
                            color: selectedState ? "black" : "grey", // Change color to black when a state is selected
                            outline: "none",
                            fontWeight: "500",
                            fontSize: "15px",
                            fontFamily: "Inter, sans-serif",
                          }}
                        >
                          <option value="" disabled>
                            State
                          </option>
                          {stateData &&
                            stateData
                              .sort((a, b) => a.name.localeCompare(b.name)) // Sort states alphabetically
                              .map((item) => (
                                <option key={item.id} value={item.name}>
                                  {item.name.charAt(0).toUpperCase() +
                                    item.name.slice(1).toLowerCase()}
                                </option>
                              ))}
                        </select>

                        {errors.userState && (
                          <Typography color="red">
                            {errors.userState}
                          </Typography>
                        )}
                      </Box>
                      {/* city */}
                      <Box
                        sx={{
                          marginTop: {
                            xs: "15px",
                            sm: "10px",
                            md: "10px",
                            lg: "10px",
                          },
                        }}
                        display={{
                          md: "none",
                          lg: "none",
                        }}
                      >
                        <select
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(e.target.value)}
                          style={{
                            borderBottom: "1px solid #000",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            width: "100%",
                            height: "45px",
                            borderRadius: "0px",
                            backgroundColor: "transparent",
                            color: selectedCity ? "black" : "grey", // Change color to black when a city is selected
                            outline: "none",
                            fontWeight: "500",
                            fontSize: "15px",
                            fontFamily: "Inter, sans-serif",
                          }}
                        >
                          <option value="" disabled>
                            City
                          </option>
                          {cityData &&
                            cityData
                              .sort((a, b) => a.localeCompare(b)) // Sort cities alphabetically
                              .map((item, index) => (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              ))}
                        </select>

                        {errors.userCity && (
                          <Typography color="red">{errors.userCity}</Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* Submit All Button */}
                <Button
                  variant="contained"
                  sx={{
                    position: "fixed",
                    bottom: {
                      xs: "40px",
                      sm: "40px",
                      md: "10px",
                      lg: "10px",
                    },
                    width: {
                      xs: "60%",
                      sm: "60%",
                      md: "60%",
                      lg: "60%",
                    },
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "#FF1F2F",
                    "&:hover": { backgroundColor: "#FF1F2F" },
                  }}
                  onClick={handleSubmit}

                  // disabled={isSubmitting}
                >
                  Submit All Products
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Modal>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Header;
