import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Grid,
    Typography,
    Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqItemsLeft = [
        {
            question: "What is Airport Branding, and Why is It Effective?",
            answer: "Airport branding is a premium advertising strategy that targets travelers through digital screens, kiosks, and banners in airport premises. It is highly effective in locations like Prayagraj Airport, where ads reach a captive audience of domestic and international travelers, ensuring maximum visibility and engagement.",
        },
        {
            question: "Which Airports Do You Cover for Branding Campaigns?",

            answer: (

                <div>
                    <h4>JANUS OOH offers airport branding solutions across 20+ cities in India, including:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>

                            <p>Prayagraj Airport Bamrauli Airpor for targeting travelers in Uttar Pradesh.</p>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <p>Delhi’s IGI Airport for high-end exposure to elite and business-class audiences.
                            </p>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>


                            <p>Mumbai’s Chhatrapati Shivaji Airport for the busiest passenger traffic.</p>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <p>Bangalore’s Kempegowda Airport for tech-savvy audiences in South India.</p>
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <p>Hyderabad’s Rajiv Gandhi International Airport for premium global exposure.</p>
                        </div>

                    </div>
                </div>


            ),
        },

        {
            question: "What Are the Benefits of Airport Branding?",
            answer: (
                <div>
                    <h4>Airport branding offers exclusive advantages in high-traffic locations like Prayagraj and Delhi:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>High dwell time :</strong> Travelers spend extended periods in airports, increasing ad recall rates.
                            Premium audience: Reach decision-makers, business professionals, and affluent travelers for impactful brand visibility.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Global exposure :</strong> Airports in cities like Mumbai, Bangalore, and Prayagraj cater to both domestic and international flyers.
                        </div>


                    </div>
                </div>


            ),

        },
        {
            question: "What Types of Branding Opportunities Are Available at Airports?",

            answer: (
                <div>
                    <h4>We provide a variety of advertising formats tailored to airport audiences:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Digital displays :</strong> Engage tech-savvy travelers with dynamic visuals in terminals of Prayagraj and Bangalore Airports.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Kiosks and lounges :</strong> Premium placements in business lounges at airports like Delhi IGI and Hyderabad.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Banners and hoardings :</strong> High-visibility zones in departures, arrivals, and baggage claim areas at Mumbai and Prayagraj.
                        </div>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>In-flight advertising :</strong> Target a captive audience on flights departing from Prayagraj and other metro cities.
                        </div>

                    </div>
                </div>


            ),
        },
    ];

    const faqItemsRight = [
        { question: "Why Is Prayagraj Airport an Emerging Hub for Airport Advertising?", answer: "Prayagraj Airport (Bamrauli) is witnessing increasing passenger traffic due to the city's growing business and tourism sectors. Advertising here provides an opportunity to reach travelers heading to cultural destinations, business meetings, or local events, offering untapped potential in tier-2 cities." },
        {
            question: "What Are the Costs of Airport Branding in Prayagraj and Other Cities?",
            answer: (
                <div>
                    <h4>Costs depend on factors such as location, duration, and format:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Prayagraj Airport :</strong> Affordable rates for digital screens and static banners in high-traffic areas.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Delhi IGI and Mumbai Airports :</strong> Higher costs for premium spots with elite traveler exposure.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Regional airports :</strong> Budget-friendly options for banners and kiosk placements in smaller hubs like Lucknow and Prayagraj.
                        </div>


                    </div>
                </div>


            ),
        },
        {
            question: "Can Airport Branding Target Specific Demographics Like Business Travelers?",
            answer: (
                <div>
                    <h4>Yes, airport branding is ideal for targeting niche demographics, such as business professionals in:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Business lounges :</strong> Engage high-net-worth individuals in lounges at Prayagraj, Delhi, and Bangalore Airports.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Departure zones :</strong> Focus on frequent flyers and decision-makers awaiting flights in premium sections.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Arrivals :</strong> Capture attention immediately as passengers arrive in cities like Prayagraj and Mumbai.
                        </div>


                    </div>
                </div>


            ),
        },
        {
            question: "How Do You Measure the Impact of Airport Branding Campaigns?",
            
            answer: (
                <div>
                    <h4>We use advanced methods to track your campaign’s success:</h4>
                    <div style={{ paddingLeft: "0px" }}>
                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px", // Space for the bullet
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%", // Circular bullet
                                }}
                            ></span>
                            <strong>Impressions :</strong> Based on airport traffic data, such as footfall in Prayagraj and Mumbai Airports.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Engagement :</strong> Analytics from digital screens, including click-through rates on interactive ads.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>Audience surveys :</strong> Measure brand recall among travelers in key hubs like Delhi and Prayagraj.
                        </div>

                        <div
                            style={{
                                position: "relative",
                                paddingLeft: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "30%",
                                    transform: "translateY(-50%)",
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                }}
                            ></span>
                            <strong>QR code tracking :</strong> Directly track user interaction in campaigns at airports across India.
                        </div>

                    </div>
                </div>


            ),
        },
    ];

    return (
        <Grid container sx={{ backgroundColor: "#E8E8E8" }}>
            <Container maxWidth="lg" sx={{ marginTop: "40px", marginBottom: "40px" }}>
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: "20px",
                        color: "red",
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    FAQ's
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        marginBottom: "30px",
                        fontSize: "14px",
                        color: "gray",
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    Elevate your brand recall and broaden your reach with a strategically <br />
                    crafted range of outdoor advertising services.
                </Typography>

                <Grid container spacing={4}>
                    {/* Left Column */}
                    <Grid item xs={12} sm={12} md={6}>
                        {faqItemsLeft.map((item, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleChange(`panel${index}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}bh-content`}
                                    id={`panel${index}bh-header`}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={12} sm={12} md={6}>
                        {faqItemsRight.map((item, index) => (
                            <Accordion
                                key={index + faqItemsLeft.length}
                                expanded={expanded === `panel${index + faqItemsLeft.length}`}
                                onChange={handleChange(`panel${index + faqItemsLeft.length}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + faqItemsLeft.length}bh-content`}
                                    id={`panel${index + faqItemsLeft.length}bh-header`}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default FAQ;
