export const indianStates = [
  {
    id: "1",
    stateName: "Andhra Pradesh",
  },
  {
    id: "2",
    stateName: "Arunachal Pradesh",
  },
  {
    id: "3",
    stateName: "Assam",
  },
  {
    id: "4",
    stateName: "Bihar",
  },
  {
    id: "5",
    stateName: "Chhattisgarh",
  },
  {
    id: "6",
    stateName: "Goa",
  },
  {
    id: "7",
    stateName: "Gujarat",
  },
  {
    id: "8",
    stateName: "Haryana",
  },
  {
    id: "9",
    stateName: "Himachal Pradesh",
  },
  {
    id: "10",
    stateName: "Jharkhand",
  },
  {
    id: "11",
    stateName: "Karnataka",
  },
  {
    id: "12",
    stateName: "Kerala",
  },
  {
    id: "13",
    stateName: "Madhya Pradesh",
  },
  {
    id: "14",
    stateName: "Maharashtra",
  },
  {
    id: "15",
    stateName: "Manipur",
  },
  {
    id: "16",
    stateName: "Meghalaya",
  },
  {
    id: "17",
    stateName: "Mizoram",
  },
  {
    id: "18",
    stateName: "Nagaland",
  },
  {
    id: "19",
    stateName: "Odisha",
  },
  {
    id: "20",
    stateName: "Punjab",
  },
  {
    id: "21",
    stateName: "Rajasthan",
  },
  {
    id: "22",
    stateName: "Sikkim",
  },
  {
    id: "23",
    stateName: "Tamil Nadu",
  },
  {
    id: "24",
    stateName: "Telangana",
  },
  {
    id: "25",
    stateName: "Tripura",
  },
  {
    id: "26",
    stateName: "Uttar Pradesh",
  },
  {
    id: "27",
    stateName: "Uttarakhand",
  },
  {
    id: "28",
    stateName: "West Bengal",
  },
];
