import React, { useEffect } from "react";

// MUI
import { Grid, Typography, Box } from "@mui/material";

import { Helmet } from "react-helmet";

// REACT_ROUTER-DOM
import { useLocation } from "react-router-dom";

// IMAGES
import Fullpageadvertisement from "../../FullPageAdvertisement/Fullpageadvertisement";

// CSS
import "./Fullblog.css";

function createMarkup(htmlContent) {
  return { __html: htmlContent };
}

const Fullblog = () => {
  useEffect(() => {}, []);

  const location = useLocation();
  const { blog } = location.state || {};
  console.log("hello blog" , blog)

  if (!blog) {
    return <Typography>No blog data available.</Typography>;
  }

  // Pagination

  return (
    <>
      <Helmet>
        <title>{blog?.seo_title}</title>
        <meta name="description" content={blog?.seo_description} />
      </Helmet>
      <Box
        sx={{ marginTop: { xs: "60px", sm: "60px", md: "64px", lg: "64px" } ,
        marginBottom: { xs: "30px", sm: "30px", md: "60px", lg: "60px" } }}
      >
        {/* IMAGE */}
        <Grid
          container
          sx={
            {
              // backgroundColor: "green",
            }
          }
        >
          <Grid
            xs={12}
            sm={12}
            md={0.5}
            lg={0.5}
            // sx={{ backgroundColor: "orange" }}
          ></Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={8.5}
            lg={8.5}
            sx={
              {
                // backgroundColor: "aqua",
              }
            }
          >
            <Box>
              <Typography
                sx={{
                  color: "#D2122E",
                  fontSize: { xs: "21px", sm: "21px", md: "30px", lg: "30px" },
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "500",
                  marginTop: { xs: "10px", sm: "10px", md: "20px", lg: "20px" },
                  marginBottom: {
                    xs: "3px",
                    sm: "3px",
                    md: "15px",
                    lg: "15px",
                  },
                  lineHeight: { xs: "1.2", sm: "1.2", md: "35px", lg: "35px" },
                  paddingLeft: { xs: "20px", sm: "20px", md: "0px", lg: "0px" },
                  paddingRight: {
                    xs: "20px",
                    sm: "20px",
                    md: "0px",
                    lg: "0px",
                  },
                  // width:"100%"
                }}
              >
                {/* {SingleBlog?.title} */}
                {blog?.blog_title}
                
              </Typography>
            </Box>

            {/* IMAGE */}
            <Box
              sx={{
                marginTop: {
                  xs: "15px",
                  sm: "15px",
                  md: "5px",
                  lg: "5px",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                  },
                  // height: {
                  //   xs: "100%",
                  //   sm: "100%",
                  //   md: "450px",
                  //   lg: "450px",
                  // },
                  borderRadius: {
                    xs: "10px",
                    sm: "10px",
                    md: "5px",
                    lg: "5px",
                  },
                  paddingRight: {
                    xs: "20px",
                    sm: "20px",
                    md: "0px",
                    lg: "0px",
                  },
                  paddingLeft: { xs: "20px", sm: "20px", md: "0px", lg: "0px" },
                }}
                alt="ArticleImage"
                src={blog?.image}
              />
            </Box>

            {/* DESCRIPTION */}
            <Box>
              <Typography
                sx={{
                  marginTop: {
                    xs: "15px",
                    sm: "15px",
                    md: "20px",
                    lg: "20px",
                  },
                  marginBottom: {
                    xs: "10px",
                    sm: "10px",
                    md: "0px",
                    lg: "0px",
                  },
                  overflow: "hidden",
                  lineHeight: {
                    xs: "26px",
                    sm: "26px",
                    md: "26px",
                    lg: "26px",
                  },
                  fontSize: { xs: "18px", sm: "18px", md: "17px", lg: "17px" },
                  backgroundColor: "#fff",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "500",
                  paddingLeft: { xs: "20px", sm: "20px", md: "0px", lg: "0px" },
                  paddingRight: {
                    xs: "20px",
                    sm: "20px",
                    md: "0px",
                    lg: "0px",
                  },
                }}
                dangerouslySetInnerHTML={createMarkup(blog?.description)}
              >
                {/* {blog?.description} */}
              </Typography>
            </Box>
          </Grid>

          {/* Advertisement */}
          <Grid
            item
            xs={12}
            sm={12}
            md={2.5}
            lg={2.5}
            sx={
              {
                // backgroundColor: "pink",
                // marginTop:"10px"
              }
            }
          >
            <Fullpageadvertisement />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            lg={0.5}
            // sx={{ backgroundColor: "orange" }}
          ></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Fullblog;
