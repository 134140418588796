import store from "../store";
import types from "../types";

const { dispatch } = store;

export function addToCart(data) {
  const cart = JSON.parse(localStorage.getItem("CartItems")) || [];

  const isItemInCart = cart.some((item) => item.uniquecode === data.uniquecode);

  if (!isItemInCart) {
    const updatedCart = [...cart, data];
    localStorage.setItem("CartItems", JSON.stringify(updatedCart));
    dispatch({
      type: types.ADD_CART_ITEM,
      payload: updatedCart,
    });
  } else {
    alert("This product is already in the cart!");
  }
}

export function removeFromCart(productId) {
  const cart = JSON.parse(localStorage.getItem("CartItems")) || [];
  console.log("DELETE CART ITEM", cart, productId);
  const updatedCart = cart.filter((item) => item?.id !== productId);
  localStorage.setItem("CartItems", JSON.stringify(updatedCart));
  dispatch({
    type: types.REMOVE_FROM_CART,
    payload: updatedCart
  });
}

export const fetchCartItems = () => {
  const cart = localStorage.getItem("CartItems");
  console.log("fetch-Cart-Items" , cart)
  dispatch({
    type: types.GET_CART_ITEMS,
    payload: cart ? JSON.parse(cart) : null
  });
};
