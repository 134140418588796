// import React, { useEffect, useState } from "react";

// // MATERIAL-UI
// import { Typography, Grid, Box } from "@mui/material";

// // IMAGES
// import { imageNotFound } from "../../DesignData/Images/Images";

// // CSS
// import "./Fullcard.css";

// // REACT-ROUTER-DOM
// import { useLocation, useParams } from "react-router-dom";

// // REACT-HELMET
// import { Helmet } from "react-helmet";

// // PAGES
// import Popupcomponent from "../PopUp/Popupcomponent";

// // Accordian
// import Faq from "../FAQ/Faq";

// // TOASTI-FY
// import { ToastContainer, toast } from "react-toastify";
// import {
//   CategoryDynamicText,
//   CategoryStaticText,
//   FourAlignment,
//   FullcardImage,
//   FullcardImageMargins,
//   FullcardProductDescription,
//   FullcardProductName,
//   FullcardSubcategoryName,
//   IlluminationDynamicText,
//   IlluminationStaticText,
//   RequestCallButton,
//   RequestCallButtonAlign,
//   RequestCallButtonText,
//   SizeDynamicText,
//   SizeStaticText,
//   TotalAreaDynamicText,
//   TotalAreaStaticText,
//   UniqueCodeDynamicText,
//   UniqueCodeStaticText,
// } from "../Styles/Styles";

// import { addToCart } from "../../redux/actions/Cart";

// const Fullcard = () => {
//   // accordian
//   const [DATA, setDATA] = useState(null);

//   const [ProductId, setProductId] = useState("");
//   const [productUrl, setProductUrl] = useState("");

//   const location = useLocation();
//   const { category, seotitle } = useParams();

//   useEffect(() => {
//     const ProductUrl = location?.pathname?.split("/")[2];
//     console.log("GET PRODUCT DATA", ProductUrl);
//     getProductDetails(ProductUrl);
//     setDATA(location?.state?.data);
//   }, []);

//   async function getProductDetails(productUrl) {
//     fetch(
//       `https://www.januskoncepts.in/janusoohadmin/api/fetchurl/fetchurl.php?url=${productUrl}`
//     )
//       .then((response) => {
//         return response.json();
//       })
//       .then((data) => {
//         console.log("hello product Url", data);
//         console.log("Fetched image:", data?.data[0]?.image); // Log fetched image
//         setDATA(data?.data[0]);
//       });
//   }

//   // POP-UP
//   const [isOpen, setIsOpen] = useState(false);

//   const togglePopup = () => {
//     setIsOpen(!isOpen);
//   };

//   // Toastify
//   const showToastMessage = () => {
//     toast.success("Success Notification !", {
//       position: toast.POSITION.TOP_RIGHT,
//     });
//   };

//   return (
//     <>
//       <Helmet>
//         <title>
//           {DATA?.seo_title
//             ? DATA?.seo_title
//             : `Top Outdoor Advertising Agency in ${
//                 DATA?.state_name.charAt(0).toUpperCase() +
//                 DATA?.state_name.slice(1).toLowerCase()
//               } for Maximum Impact`}
//         </title>
//         <meta
//           name="description"
//           content={
//             DATA?.seo_description
//               ? DATA?.seo_description
//               : `Discover the best outdoor advertising agency in ${
//                   DATA?.state_name.charAt(0).toUpperCase() +
//                   DATA?.state_name.slice(1).toLowerCase()
//                 }, specializing in innovative campaigns that elevate your brand visibility and engage your target audience effectively`
//           }
//         />
//       </Helmet>

//       {isOpen && (
//         <Popupcomponent
//           ProductId={ProductId}
//           ProductUniqueCode={DATA?.uniquecode}
//           handleClose={togglePopup}
//         />
//       )}
//       <Box>
//         <Grid container sx={{ marginTop: "65px" }}>
//           <Grid
//             item
//             xs={12}
//             sm={12}
//             md={0.5}
//             lg={0.5}
//             // sx={{ backgroundColor: "red" }}
//           ></Grid>
//           <Grid
//             item
//             xs={12}
//             sm={12}
//             md={11}
//             lg={11}
//             // sx={{ backgroundColor: "green" }}
//           >
//             <Grid container>
//               {/* IMAGE */}
//               <Grid
//                 item
//                 xs={12}
//                 sm={12}
//                 md={6}
//                 lg={6}
//                 // sx={{ backgroundColor: "orange" }}
//               >
//                 <Box sx={FullcardImageMargins}>
//                   <Box
//                     component="img"
//                     sx={FullcardImage}
//                     alt={DATA?.product_name}
//                     src={DATA?.image ? DATA?.image : imageNotFound}
//                     // src={DATA?.image}
//                     // src={imageNotFound}
//                   />
//                 </Box>
//               </Grid>

//               {/* TextContent */}
//               <Grid
//                 item
//                 xs={12}
//                 sm={12}
//                 md={6}
//                 lg={6}
//                 // sx={{ backgroundColor: "grey" }}
//               >
//                 <Box
//                   sx={{
//                     marginTop: { xs: "5px", sm: "5px", md: "60px", lg: "60px" },
//                     marginBottom: {
//                       xs: "5px",
//                       sm: "5px",
//                       md: "60px",
//                       lg: "60px",
//                     },
//                   }}
//                 >
//                   {/* Subcategory Name */}
//                   <Box>
//                     <Box>
//                       <Typography sx={FullcardSubcategoryName}>
//                         {/* {DATA?.category.toUpperCase()} */}
//                         {DATA?.sub_category_name.toUpperCase()}
//                         {/* {DATA?.state_name} */}
//                       </Typography>
//                     </Box>

//                     {/* Productname */}
//                     <Typography sx={FullcardProductName}>
//                       {DATA?.product_name}
//                     </Typography>
//                   </Box>

//                   {/* UNIQUE CODE */}
//                   <Box
//                     sx={{
//                       marginTop: "15px",
//                       paddingLeft: {
//                         xs: "10px",
//                         sm: "10px",
//                         md: "0px",
//                         lg: "0px",
//                       },
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         display: "flex",
//                         flexDirection: "row",
//                       }}
//                     >
//                       <Typography sx={UniqueCodeStaticText}>
//                         Unique code :
//                       </Typography>
//                       <Typography sx={UniqueCodeDynamicText}>
//                         {/* &nbsp;{DATA?.code.toUpperCase()} */}
//                         &nbsp;{DATA?.uniquecode}
//                       </Typography>
//                     </Box>
//                   </Box>

//                   {/* Category - Size- illumination - Total area */}

//                   <Box sx={FourAlignment}>
//                     <Box sx={{ display: "flex", flexDirection: "column" }}>
//                       {/* Category */}
//                       <Box>
//                         <Typography sx={CategoryStaticText}>
//                           Category
//                         </Typography>
//                       </Box>
//                       <Box>
//                         <Typography sx={CategoryDynamicText}>
//                           {DATA?.category_name}
//                         </Typography>
//                       </Box>
//                     </Box>

//                     {/* Size */}
//                     <Box sx={{ display: "flex", flexDirection: "column" }}>
//                       <Box>
//                         <Typography sx={SizeStaticText}>Size</Typography>
//                       </Box>
//                       <Box>
//                         <Typography sx={SizeDynamicText}>
//                           {DATA?.size}
//                         </Typography>
//                       </Box>
//                     </Box>

//                     {/* Illumniation */}
//                     <Box sx={{ display: "flex", flexDirection: "column" }}>
//                       <Box>
//                         <Typography sx={IlluminationStaticText}>
//                           Illumination
//                         </Typography>
//                       </Box>
//                       <Box>
//                         <Typography sx={IlluminationDynamicText}>
//                           {DATA?.illumination}
//                         </Typography>
//                       </Box>
//                     </Box>

//                     {/* total area */}
//                     <Box sx={{ display: "flex", flexDirection: "column" }}>
//                       <Box>
//                         <Typography sx={TotalAreaStaticText}>
//                           Total Area
//                         </Typography>
//                       </Box>
//                       <Box>
//                         <Typography sx={TotalAreaDynamicText}>
//                           {DATA?.total_area}
//                         </Typography>
//                       </Box>
//                     </Box>
//                   </Box>

//                   {/* Description */}
//                   <Box
//                     sx={{
//                       marginTop: {
//                         xs: "14px",
//                         sm: "14px",
//                         md: "20px",
//                         lg: "20px",
//                       },
//                     }}
//                   >
//                     <Typography sx={FullcardProductDescription}>
//                       {DATA?.description
//                         ? DATA?.description
//                         : "Boost your brand visibility and expand your reach with strategically placed hoardings in South Delhi, Delhi Located in prime high-traffic areas with heavy footfall, these advertisements ensure maximum exposure to bystanders, pedestrians, and commuters, driving brand recall and engagement."}
//                     </Typography>
//                   </Box>

//                   {/* request a callback button */}
//                   <Box
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       alignItems: "center",
//                       // justifyContent: "space-between",
//                     }}
//                   >
//                     <Box sx={RequestCallButtonAlign}>
//                       <Box
//                         sx={RequestCallButton}
//                         onClick={togglePopup}
//                         // onClick={() => console.log(DATA)}
//                       >
//                         <Typography sx={RequestCallButtonText}>
//                           Request a Callback
//                         </Typography>
//                       </Box>
//                     </Box>
//                     {/* Add to cart button */}
//                     <Box
//                       sx={{
//                         backgroundColor: "#FF1F2F",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         display: "flex",
//                         borderRadius: "10px",
//                         height: "40px",
//                         ":hover": {
//                           backgroundColor: "#e0000f",
//                         },
//                         boxShadow: "4",
//                         marginLeft: "20px",
//                         cursor: "pointer",
//                       }}
//                       onClick={() => {
//                         const userData = JSON.parse(
//                           localStorage.getItem("registrationData")
//                         );
//                         console.log(
//                           " hello UserID:",
//                           userData.user.id,
//                           DATA.id
//                         );
//                         addToCart({
//                           id: DATA.id,
//                           user_id: userData.user.id,
//                         });
//                       }}
//                     >
//                       <Typography
//                         sx={{
//                           padding: "8px",
//                           color: "white",
//                           fontSize: "15px",
//                           fontWeight: "500",
//                           ":hover": {
//                             color: "white",
//                           },
//                         }}
//                       >
//                         Add to cart
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             sm={12}
//             md={0.5}
//             lg={0.5}
//             // sx={{ backgroundColor: "yellow" }}
//           ></Grid>
//         </Grid>

//         {/* Accordian */}
//         <Faq />
//       </Box>
//     </>
//   );
// };

// export default Fullcard;

import React, { useEffect, useState } from "react";

// MATERIAL-UI
import { Typography, Grid, Box } from "@mui/material";

// IMAGES
import { imageNotFound } from "../../DesignData/Images/Images";

// CSS
import "./Fullcard.css";

// REACT-ROUTER-DOM
import { useLocation, useParams } from "react-router-dom";

// REACT-HELMET
import { Helmet } from "react-helmet";

// PAGES
import Popupcomponent from "../PopUp/Popupcomponent";

// Accordian
import Faq from "../FAQ/Faq";

// TOASTI-FY
import { ToastContainer, toast } from "react-toastify";
import {
  AddtocartButtonDesign,
  CategoryDynamicText,
  CategoryStaticText,
  FourAlignment,
  FullcardImage,
  FullcardImageMargins,
  FullcardProductDescription,
  FullcardProductName,
  FullcardSubcategoryName,
  IlluminationDynamicText,
  IlluminationStaticText,
  RequestCallButton,
  RequestCallButtonAlign,
  RequestCallButtonText,
  SizeDynamicText,
  SizeStaticText,
  TotalAreaDynamicText,
  TotalAreaStaticText,
  UniqueCodeDynamicText,
  UniqueCodeStaticText,
} from "../Styles/Styles";

import { addToCart } from "../../redux/actions/Cart";
import store from "../../redux/store";
import types from "../../redux/types";

const { dispatch } = store;

const Fullcard = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  // accordian
  const [DATA, setDATA] = useState(null);

  const [ProductId, setProductId] = useState("");
  const [productUrl, setProductUrl] = useState("");

  const location = useLocation();
  const { category, seotitle } = useParams();

  useEffect(() => {
    const ProductUrl = location?.pathname?.split("/")[2];
    console.log("GET PRODUCT DATA", ProductUrl);
    getProductDetails(ProductUrl);
    setDATA(location?.state?.data);
  }, []);

  async function getProductDetails(productUrl) {
    fetch(
      `https://www.januskoncepts.in/janusoohadmin/api/fetchurl/fetchurl.php?url=${productUrl}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("hello product Url", data);
        console.log("Fetched image:", data?.data[0]?.image); // Log fetched image
        setDATA(data?.data[0]);
      });
  }

  // POP-UP
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  // Toastify
  const showToastMessage = () => {
    toast.success("Success Notification !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {DATA?.seo_title
            ? DATA?.seo_title
            : `Top Outdoor Advertising Agency in ${
                DATA?.state_name.charAt(0).toUpperCase() +
                DATA?.state_name.slice(1).toLowerCase()
              } for Maximum Impact`}
        </title>
        <meta
          name="description"
          content={
            DATA?.seo_description
              ? DATA?.seo_description
              : `Discover the best outdoor advertising agency in ${
                  DATA?.state_name.charAt(0).toUpperCase() +
                  DATA?.state_name.slice(1).toLowerCase()
                }, specializing in innovative campaigns that elevate your brand visibility and engage your target audience effectively`
          }
        />
      </Helmet>

      {isOpen && (
        <Popupcomponent
          ProductId={ProductId}
          ProductUniqueCode={DATA?.uniquecode}
          handleClose={togglePopup}
        />
      )}
      <Box>
        <Grid container sx={{ marginTop: "65px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            lg={0.5}
            // sx={{ backgroundColor: "red" }}
          ></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={11}
            // sx={{ backgroundColor: "green" }}
          >
            <Grid container>
              {/* IMAGE */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                // sx={{ backgroundColor: "orange" }}
              >
                <Box sx={FullcardImageMargins}>
                  <Box
                    component="img"
                    sx={FullcardImage}
                    alt={DATA?.product_name}
                    src={DATA?.image ? DATA?.image : imageNotFound}
                    // src={DATA?.image}
                    // src={imageNotFound}
                  />
                </Box>
              </Grid>

              {/* TextContent */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                // sx={{ backgroundColor: "grey" }}
              >
                <Box
                  sx={{
                    marginTop: { xs: "5px", sm: "5px", md: "60px", lg: "60px" },
                    marginBottom: {
                      xs: "5px",
                      sm: "5px",
                      md: "60px",
                      lg: "60px",
                    },
                  }}
                >
                  {/* Subcategory Name */}
                  <Box>
                    <Box>
                      <Typography sx={FullcardSubcategoryName}>
                        {/* {DATA?.category.toUpperCase()} */}
                        {DATA?.sub_category_name.toUpperCase()}
                        {/* {DATA?.state_name} */}
                      </Typography>
                    </Box>

                    {/* Productname */}
                    <Typography sx={FullcardProductName}>
                      {DATA?.product_name}
                    </Typography>
                  </Box>

                  {/* UNIQUE CODE */}
                  <Box
                    sx={{
                      marginTop: "15px",
                      paddingLeft: {
                        xs: "10px",
                        sm: "10px",
                        md: "0px",
                        lg: "0px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Typography sx={UniqueCodeStaticText}>
                        Unique code :
                      </Typography>
                      <Typography sx={UniqueCodeDynamicText}>
                        {/* &nbsp;{DATA?.code.toUpperCase()} */}
                        &nbsp;{DATA?.uniquecode}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Category - Size- illumination - Total area */}

                  <Box sx={FourAlignment}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {/* Category */}
                      <Box>
                        <Typography sx={CategoryStaticText}>
                          Category
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={CategoryDynamicText}>
                          {DATA?.category_name}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Size */}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box>
                        <Typography sx={SizeStaticText}>Size</Typography>
                      </Box>
                      <Box>
                        <Typography sx={SizeDynamicText}>
                          {DATA?.size}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Illumniation */}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box>
                        <Typography sx={IlluminationStaticText}>
                          Illumination
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={IlluminationDynamicText}>
                          {DATA?.illumination}
                        </Typography>
                      </Box>
                    </Box>

                    {/* total area */}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box>
                        <Typography sx={TotalAreaStaticText}>
                          Total Area
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={TotalAreaDynamicText}>
                          {DATA?.total_area}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {/* Description */}
                  <Box
                    sx={{
                      marginTop: {
                        xs: "14px",
                        sm: "14px",
                        md: "20px",
                        lg: "20px",
                      },
                    }}
                  >
                    <Typography sx={FullcardProductDescription}>
                      {DATA?.description
                        ? DATA?.description
                        : "Boost your brand visibility and expand your reach with strategically placed hoardings in South Delhi, Delhi Located in prime high-traffic areas with heavy footfall, these advertisements ensure maximum exposure to bystanders, pedestrians, and commuters, driving brand recall and engagement."}
                    </Typography>
                  </Box>

                  {/* request a callback button */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                  >
                    <Box sx={RequestCallButtonAlign}>
                      <Box
                        sx={RequestCallButton}
                        onClick={togglePopup}
                        // onClick={() => console.log(DATA)}
                      >
                        <Typography sx={RequestCallButtonText}>
                          Request a Callback
                        </Typography>
                      </Box>
                    </Box>
                    {/* Add to cart button */}
                    <Box
                      sx={AddtocartButtonDesign}
                      // onClick={() => {
                      //   const userData = JSON.parse(
                      //     localStorage.getItem("registrationData")
                      //   );
                      //   if (userData) {
                      //     addToCart({
                      //       id: DATA.id,
                      //       user_id: userData.user.id,
                      //     });
                      //   } else {
                      //     dispatch({
                      //       type: types.SHOW_FORM,
                      //       payload: true,
                      //     });
                      //   }
                      // }}

                      // onClick={() => {
                      //   const cart = JSON.parse(localStorage.getItem("CartItems"));
                      //   const val = cart ? [...cart, DATA] : [DATA];
                      //   localStorage.setItem("CartItems", JSON.stringify(val));
                      //   window.location.reload(false);
                      // }}

                      onClick={() => {
                        addToCart(DATA)
                      }}

                    >
                      <Typography
                        sx={{
                          padding: "8px",
                          color: "white",
                          fontSize: "15px",
                          fontWeight: "500",
                          ":hover": {
                            color: "white",
                          },
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        Add to cart
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            lg={0.5}
            // sx={{ backgroundColor: "yellow" }}
          ></Grid>
        </Grid>

        {/* Accordian */}
        <Faq />
      </Box>
    </>
  );
};

export default Fullcard;
