/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";

// REACT-HELMET
import { Helmet } from "react-helmet";

// MUI
import { Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { states, stateDistricts } from "../../assets/json/statesCity";
import videotwo from "../../assets/video/VideoWithoutText.mp4";

import { getStateAndCity } from "../../redux/actions/Outdoor";

import "./Allout.css";

const Allout = () => {
  const { allstates } = useSelector((state) => state.OutdoorReducer);

  useEffect(() => {
    getStateAndCity();
  }, []);

  return (
    <>
      <Helmet>
        <title>Prime Hoarding: Maximizing Outdoor Exposure</title>
        <meta
          name="description"
          content={
            "Prime Hoarding: Elevating Outdoor Exposure with Billboard Advertisement Solutions. Reach new heights of visibility and engagement in the outdoor landscape."
          }
        />
      </Helmet>
      {/* Video */}
      <Box
        sx={{
          marginTop: { xs: "51px", sm: "51px", md: "0px", lg: "0px" },
        }}
      >
        {/* VIDEO */}
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            // display={{ xs: "none", lg: "block" }}
          >
            <Box sx={{ position: "relative" }}>
              {/* Video with gradient overlay */}
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                  height: { xs: "30vh", sm: "30vh", md: "60vh", lg: "60vh" },
                  position: "relative", // Make this box relative for absolute positioning of the gradient
                }}
              >
                {/* Video Element */}
                <video
                  src={videotwo}
                  autoPlay
                  loop
                  muted
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />

                {/* Gradient Overlay */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
                    zIndex: 1, // Ensures gradient is above the video
                  }}
                />
              </Box>

              {/* Text */}
              <Grid
                container
                sx={{
                  position: "absolute",
                  bottom: {
                    xs: "35%",
                    sm: "35%",
                    md: "35%",
                    lg: "35%",
                  },
                  zIndex: 2, // Ensures text is above the gradient
                }}
              >
                <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>

                <Grid item xs={12} sm={12} md={11} lg={11}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Desktop Title */}
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          fontSize: "50px",
                          fontWeight: "50px",
                          fontFamily: "Inter, sans-serif",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        All&nbsp;
                        <span style={{ color: "#FF1F2F" }}>Hoardings</span>
                      </Typography>
                    </Box>

                    {/* Mobile Title */}
                    <Box
                      sx={{
                        display: {
                          md: "none",
                          lg: "none",
                        },
                        height: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "30px",
                          fontWeight: "500",
                          fontFamily: "Inter, sans-serif",
                          color: "#fff",
                          textAlign: "center",
                          lineHeight: "35px",
                        }}
                      >
                        All &nbsp;
                        <span
                          style={{
                            color: "#FF1F2F",
                            fontSize: "30px",
                            fontWeight: "600",
                          }}
                        >
                          Hoardings
                        </span>
                      </Typography>
                    </Box>

                    {/* Desktop Description */}
                    <Box
                      sx={{
                        marginTop: {
                          xs: "10px",
                          sm: "10px",
                          md: "0px",
                          lg: "0px",
                        },
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                        padding: "0px 150px 0px 150px",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "17px",
                          fontWeight: "500",
                          fontFamily: "Inter, sans-serif",
                          color: "#fff",
                          textAlign: "center",
                          lineHeight: "25px",
                        }}
                      >
                        {/* Leverage lifeline of the city */}
                      </Typography>
                    </Box>

                    {/* Mobile Desciption */}
                    <Box
                      sx={{
                        marginTop: "15px",
                        display: {
                          md: "none",
                          lg: "none",
                        },
                        padding: "0px 20px 0px 20px",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "15px",
                          fontWeight: "500",
                          fontFamily: "Inter, sans-serif",
                          color: "#fff",
                          marginTop: "-5px",
                          lineHeight: "20px",
                          textAlign: "center",
                        }}
                      >
                        {/* Leverage lifeline of the city */}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Content */}
      <Box
        sx={{
          marginTop: { xs: "10px", sm: "10px", md: "30px", lg: "30px" },
          marginBottom: { xs: "20px", sm: "20px", md: "30px", lg: "30px" },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={0.7}
            lg={0.7}
            // sx={{ backgroundColor: "red" }}
          ></Grid>
          <Grid item xs={12} sm={12} md={10.6} lg={10.6}>
            <Box
              sx={{
                marginLeft: {
                  xs: "10px",
                  sm: "10px",
                  md: "0px",
                  lg: "0px",
                },
                marginTop: {
                  xs: "20px",
                  sm: "20px",
                  md: "0px",
                  lg: "0px",
                },
                marginBottom: {
                  xs: "20px",
                  sm: "20px",
                  md: "0px",
                  lg: "0px",
                },
              }}
            >
              {Object.keys(stateDistricts).map((item, index) => (
                <Box>
                  <a
                    href={`https://www.janusooh.com/outdoor-advertising-agency-in-${item
                      .toLowerCase()
                      .replaceAll(" ", "-")}/`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "30px",
                          sm: "30px",
                          md: "40px",
                          lg: "40px",
                        },
                        fontFamily: "Inter, sans-serif",
                        fontWeight: "700",
                        marginBottom: "5px",
                        textDecoration: "none",
                        color: "#FF1F2F",
                        // backgroundColor:"green"
                      }}
                    >
                      {item}
                    </Typography>
                  </a>

                  {stateDistricts[item].map((city, i) => (
                    <a
                      href={`https://www.janusooh.com/outdoor-advertising-agency-in-${city
                        .toLowerCase()
                        .replaceAll(" ", "-")}/`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "15px",
                            md: "25px",
                            lg: "25px",
                          },
                          color: "#000",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: "600",
                          width: "100%",
                        }}
                      >
                        {city}
                      </Typography>
                    </a>
                  ))}
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={0.7}
            lg={0.7}
            // sx={{ backgroundColor: "red" }}
          ></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Allout;
