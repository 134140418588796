import { Description } from "@mui/icons-material";
import {
  Search,
  call,
  Plan,
  Buy,
  polygon1,
  polygon2,
  polygon3,
  polygon4,
} from "../../../DesignData/Images/Images";

export const OurProcessData = [
  {
    id: "1",
    image: Search,
    title: "Find Us",
    poylgonImg: polygon1,
    description:
      "Discover the best outdoor ads for you.",
  },
  {
    id: "2",
    image: call,
    title: "Call Us",
    poylgonImg: polygon2,
    description:
      "Reach out now to explore our offerings.",
  },
  {
    id: "3",
    image: Plan,
    title: "Plan With Us",
    poylgonImg: polygon3,
    description:
      "Let’s collaborate to design your perfect campaign.",
  },
  {
    id: "4",
    image: Buy,
    poylgonImg: polygon4,
    title: "Buy With Us",
    description:
      "Get premium ad spaces to elevate visibility.",
  },
];
