import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="716238758163-tn7mnlvbpbgav9fcv3ci9alogd8sf6qq.apps.googleusercontent.com">

      <App />
      
    </GoogleOAuthProvider>
  </React.StrictMode>
);
