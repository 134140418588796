// import React from "react";
// import { Box, Grid, Typography } from "../../DesignData/MaterialUi/material-ui";

// import {
//   MainBox,
//   cardImage,
//   Maintitle,
//   TextBox,
//   TitleBox,
//   Alignment,
//   ColonsStyle,
//   StateAndCity,
//   StateAndCityName,
//   StateAndCityWidth,
//   StateAndCityNameWidth,
//   ColonsWidth,
//   CardsMarginForMobileView,
// } from "../Styles/Styles";

// import { imageNotFound } from "../../DesignData/Images/Images";

// // CSS
// import "./Cards.css";

// import { addToCart } from "../../redux/actions/Cart";

// const Cards = ({ data, onClick }) => {
//   // Check if the product status is inactive
//   if (data?.status?.toLowerCase() === "inactive") {
//     return null;
//   }

//   return (
//     <>
//       <Grid item xs={12} sm={12} md={4} lg={4} sx={CardsMarginForMobileView}>
//         <Box
//           sx={MainBox}
//           onClick={() => {
//             onClick();
//           }}
//         >
//           <Box sx={{ position: "relative" }}>
//             {/* Image */}
//             <Box
//               component="img"
//               sx={cardImage}
//               alt="card"
//               src={data?.image ? data.image : imageNotFound}
//             />
//             <Box>
//               {/* Red box Subcategory name */}
//               <Box
//                 sx={{
//                   bottom: "-8px",
//                   left: "10px",
//                   position: "absolute",
//                   alignItems: "center",
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     fontFamily: "Inter, sans-serif",
//                     fontSize: {
//                       xs: "18px",
//                       sm: "18px",
//                       md: "18px",
//                       lg: "18px",
//                     },
//                     fontWeight: "600",
//                     color: "white",
//                     height: "20px",
//                     width: "100%",
//                     backgroundColor: "#FF1F2F",
//                     borderRadius: "3px",
//                     padding: "15px",
//                     alignItems: "center",
//                     display: "flex",
//                     justifyContent: "center",
//                   }}
//                 >
//                   {data?.sub_category_name
//                     ? data.sub_category_name.toLowerCase() === "dooh"
//                       ? data.sub_category_name.toUpperCase()
//                       : data.sub_category_name.charAt(0).toUpperCase() +
//                         data.sub_category_name.slice(1).toLowerCase()
//                     : ""}
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//           <Box sx={TextBox}>
//             <Box sx={TitleBox}>
//               <Typography sx={Maintitle}>
//                 {data?.product_name
//                   ? data.product_name.charAt(0).toUpperCase() +
//                     data.product_name.slice(1).toLowerCase()
//                   : ""}
//               </Typography>
//               <Box
//                 sx={{
//                   marginTop: {
//                     xs: "10px",
//                     sm: "10px",
//                     md: "10px",
//                     lg: "10px",
//                   },
//                   // backgroundColor: "blue",
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Box>
//                   {/* City */}
//                   <Box sx={Alignment}>
//                     <Box sx={StateAndCityWidth}>
//                       <Typography sx={StateAndCity}>City</Typography>
//                     </Box>
//                     <Box sx={ColonsWidth}>
//                       <Typography sx={ColonsStyle}>:</Typography>
//                     </Box>
//                     <Box sx={StateAndCityNameWidth}>
//                       <Typography sx={StateAndCityName}>
//                         {data.city_name}
//                       </Typography>
//                     </Box>
//                   </Box>

//                   {/* State */}
//                   <Box sx={Alignment}>
//                     <Box sx={StateAndCityWidth}>
//                       <Typography sx={StateAndCity}>State</Typography>
//                     </Box>
//                     <Box sx={ColonsWidth}>
//                       <Typography sx={ColonsStyle}>:</Typography>
//                     </Box>
//                     <Box sx={StateAndCityNameWidth}>
//                       <Typography sx={StateAndCityName}>
//                         {data?.state_name?.toLowerCase()}
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>

//                 {/* Add to cart button */}
//                 <Box
//                   sx={{
//                     backgroundColor: "#FF1F2F",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     display: "flex",
//                     borderRadius: "10px",
//                     height: "35px",
//                     ":hover": {
//                       backgroundColor: "#e0000f",
//                     },
//                     boxShadow: "4",
//                   }}
//                   onClick={() => {
//                     const userData = JSON.parse(
//                       localStorage.getItem("registrationData")
//                     );
//                     console.log(" hello UserID:", userData.user.id);
//                     addToCart(
//                       new URLSearchParams({
//                         user_id: userData.user.id,
//                       }).toString()
//                     );
//                   }}
//                 >
//                   <Typography
//                     sx={{
//                       padding: "8px",
//                       color: "white",
//                       fontSize: "15px",
//                       fontWeight: "500",
//                       ":hover": {
//                         color: "white",
//                       },
//                     }}
//                   >
//                     Add to cart
//                   </Typography>
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Grid>
//     </>
//   );
// };

// export default Cards;

import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
} from "../../DesignData/MaterialUi/material-ui";

import {
  MainBox,
  cardImage,
  Maintitle,
  TextBox,
  TitleBox,
  Alignment,
  ColonsStyle,
  StateAndCity,
  StateAndCityName,
  StateAndCityWidth,
  StateAndCityNameWidth,
  ColonsWidth,
  CardsMarginForMobileView,
} from "../Styles/Styles";

import { imageNotFound } from "../../DesignData/Images/Images";

// CSS
import "./Cards.css";

import { addToCart } from "../../redux/actions/Cart";

import store from "../../redux/store";
import types from "../../redux/types";

const { dispatch } = store;

const Cards = ({ data, onClick }) => {
  const [DATA, setDATA] = useState(null);

  // Check if the product status is inactive
  if (data?.status?.toLowerCase() === "inactive") {
    return null;
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4} sx={CardsMarginForMobileView}>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              ...MainBox,
              // position: "relative",
              clipPath:
                "polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 100px) calc(100% - 40px), calc(100% - 100px) 100%, 0 100%)",
            }}
            onClick={() => {
              onClick();
            }}
          >
            <Box sx={{ position: "relative" }}>
              {/* Image */}
              <Box
                component="img"
                sx={cardImage}
                alt="card"
                src={data?.image ? data.image : imageNotFound}
              />
              <Box>
                {/* Red box Subcategory name */}
                <Box
                  sx={{
                    bottom: "-8px",
                    left: "10px",
                    position: "absolute",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter, sans-serif",
                      fontSize: {
                        xs: "17px",
                        sm: "17px",
                        md: "17px",
                        lg: "17px",
                      },
                      fontWeight: "600",
                      color: "white",
                      height: "20px",
                      width: "100%",
                      backgroundColor: "#FF1F2F",
                      // borderRadius: "3px",
                      padding: "15px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {data?.sub_category_name
                      ? data.sub_category_name.toLowerCase() === "dooh"
                        ? data.sub_category_name.toUpperCase()
                        : data.sub_category_name.charAt(0).toUpperCase() +
                          data.sub_category_name.slice(1).toLowerCase()
                      : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* TExtBox */}
            <Box sx={TextBox}>
              <Box sx={TitleBox}>
                <Typography sx={Maintitle}>
                  {data?.product_name
                    ? data.product_name.charAt(0).toUpperCase() +
                      data.product_name.slice(1).toLowerCase()
                    : ""}
                </Typography>
                <Box
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    {/* City */}
                    <Box sx={Alignment}>
                      <Box sx={StateAndCityWidth}>
                        <Typography sx={StateAndCity}>City</Typography>
                      </Box>
                      <Box sx={ColonsWidth}>
                        <Typography sx={ColonsStyle}>:</Typography>
                      </Box>
                      <Box sx={StateAndCityNameWidth}>
                        <Typography sx={StateAndCityName}>
                          {data.city_name}
                        </Typography>
                      </Box>
                    </Box>

                    {/* State */}
                    <Box sx={Alignment}>
                      <Box sx={StateAndCityWidth}>
                        <Typography sx={StateAndCity}>State</Typography>
                      </Box>
                      <Box sx={ColonsWidth}>
                        <Typography sx={ColonsStyle}>:</Typography>
                      </Box>
                      <Box sx={StateAndCityNameWidth}>
                        <Typography sx={StateAndCityName}>
                          {data?.state_name?.toLowerCase()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Add to cart button */}
          <Box sx={{ position: "absolute", bottom: "0px", right: "0px" }}>
            <Box
              sx={{
                backgroundColor: "#FF1F2F",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                height: "36px",
                ":hover": {
                  backgroundColor: "#e0000f",
                },
                boxShadow: "4",
                cursor: "pointer",
                transition: "transform 0.3s ease-in-out", // Smooth zoom animation
                ":hover": {
                  backgroundColor: "#e0000f",
                  transform: "scale(1.03)", // Zoom the box by 10%
                },
              }}
              // login -signup----------------------------------------

              // onClick={() => {
              //   const userData = JSON.parse(
              //     localStorage.getItem("registrationData")
              //   );
              //   if (userData) {
              //     addToCart({
              //       id: data.id,
              //       user_id: userData.user.id,
              //     });
              //   } else {
              //     dispatch({
              //       type: types.SHOW_FORM,
              //       payload: true,
              //     });
              //   }
              // }}

              // -----------------------------------------------------------------

              // onClick={() => {
              //   const cart = JSON.parse(localStorage.getItem("CartItems"));
              //   const val = cart ? [...cart, data] : [data];
              //   localStorage.setItem("CartItems", JSON.stringify(val));
              //   window.location.reload(false);
              // }}

              // onClick={() => {
              //   const cart =
              //     JSON.parse(localStorage.getItem("CartItems")) || [];

              //   const isItemInCart = cart.some(
              //     (item) => item.uniquecode === data.uniquecode
              //   );

              //   if (!isItemInCart) {
              //     const updatedCart = [...cart, data];
              //     localStorage.setItem(
              //       "CartItems",
              //       JSON.stringify(updatedCart)
              //     );
              //     // window.location.reload(false);
              //   } else {
              //     alert("This product is already in the cart!");
              //   }
              // }}

              onClick={()=>{
                addToCart(data)
              }}
            >
              <Typography
                sx={{
                  padding: "9px",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "500",
                  ":hover": {
                    color: "white",
                  },
                }}
              >
                Add to Cart
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* Add to cart button */}
      </Grid>
    </>
  );
};

export default Cards;
